import React from "react";
import logo1 from "../../assets/brickdemoicons/brick_logo_f.png";
import "./NewHeader.css";
import notification from "../../assets/images1/BellRinging.png";
import logout from "../../assets/images1/SignOut.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import Model1 from "../Modal/Notification";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { useState, useEffect } from "react";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import ButtonLink from "../ButtonLink/ButtonLink";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "react-bootstrap";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";

import Caredown from "../../assets/brickdemoicons/CaretDown.png";
import { RxHamburgerMenu } from "react-icons/rx";

export default function Header() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationnumber, setNotificationnumber] = useState("");
  const [notificationmessage, setNotificationmessage] = useState("");
  const [notificationrequestedfor, setNotificationrequestedfor] = useState("");
  const [notificationdate, setNotificationdate] = useState("");
  const [notificationstate, setNotificationstate] = useState(false);
  const [status, setStatus] = useState(false);
  const [headMenuList, setHeadMenuList] = useState([]);

  const [toggle, setToggle] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [logoutstatus, setLogoutstatus] = useState(false);
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [request, setRequest] = useState("");
  const openModal = () => {
    setModalOpen(!modalOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeModal = (notitype) => {
    // axios({
    //     method: 'GET',

    //     url: baseurl + '/api/notification/getdetail?client_id=' + userData.clientId,

    //     headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: 'Bearer ' + userData.token,
    //     }
    // })

    //     .then((response) => {

    //         const NotificationDetail2 = response.data
    //         setNotifications(NotificationDetail2)

    //     })
    //     .catch((error) => {

    //         //    //////console.log(error)

    //     })
    // if (notitype === "markallasread") {
    //     setNotificationnumber(0)
    // }

    // axios({
    //     method: 'GET',

    //     url: baseurl + '/api/notification/getnumber?client_id=' + userData.clientId,

    //     headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: 'Bearer ' + userData.token,
    //     }
    // })

    //     .then((response) => {
    //         const NotificationData1 = response.data
    //         const NotificationData = NotificationData1.number
    //         setNotificationnumber(NotificationData)

    //     })
    //     .catch((error) => {

    //     })

    setModalOpen(false);
  };

  function notiupdate(projectmenu, id, message, resourcemenu, notitype) {
    setRequest(message);

    if (notitype === "markoneasread") {
      if (message === "Site Update") {
        dispatch(
          updateLoginStatus({
            isLoggedIn: true,
            Pagename: "Projects",
            userName: userData.userName,
            userId: userData.userId,
            role: userData.role,
            token: userData.token,
            projectId: userData.projectId,
            clientId: userData.clientId,
            currentprojectId: id,
            dropdownOption: projectmenu,
            projectType: userData.projectType,
            companyName: userData.companyName,
            projectName: userData.projectName,
            // projectMenuIndex: 3,
            projectMenuIndex: 7,
            resourceMenuKey: "material",
            modalNotificationstatus: true,
            profileImage: userData.profileImage,
            headerMenus: userData.headerMenus,
            projectMenus: userData.projectMenus,
            sidebartoggleStatus: userData.sidebartoggleStatus,
            loginUsername: userData.loginUsername,
            engineerLoginStatus: false,
            subdivision: userData.subdivision,
          })
        );
      } else {
        dispatch(
          updateLoginStatus({
            isLoggedIn: true,
            Pagename: "Dashboard",
            userName: userData.userName,
            userId: userData.userId,
            role: userData.role,
            token: userData.token,
            projectId: userData.projectId,
            clientId: userData.clientId,
            currentprojectId: id,
            dropdownOption: "Project Timeline",
            projectType: userData.projectType,
            companyName: userData.companyName,
            projectName: userData.projectName,
            projectMenuIndex: 0,
            resourceMenuKey: "material",
            modalNotificationstatus: true,
            profileImage: userData.profileImage,
            headerMenus: userData.headerMenus,
            projectMenus: userData.projectMenus,
            sidebartoggleStatus: userData.sidebartoggleStatus,
            loginUsername: userData.loginUsername,
            engineerLoginStatus: false,
            subdivision: userData.subdivision,
          })
        );
      }
    }
    closeModal(notitype);
  }

  useEffect(() => {
    //Dynamic Menu

    setHeadMenuList(userData.headerMenus);
    setActiveMenu(userData.headerMenus[0]);
  }, []);

  useEffect(() => {
    // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    // //Global Data

    // if (userData.Pagename == undefined) {
    // 	dispatch(
    // 		updateLoginStatus({
    // 			isLoggedIn: true,
    // 			Pagename: 'Dashboard',
    // 			userName: userData.userName,
    // 			userId: userData.userId,
    // 			role: userData.role,
    // 			token: userData.token,
    // 			projectId: userData.projectId,
    // 			clientId: userData.clientId,
    // 			profileImage:userData.profileImage

    // 		})
    // 	)
    // }
    // axios({
    //     method: 'GET',

    //     url: baseurl + '/api/notification/getdetail?client_id=' + userData.clientId,

    //     headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: 'Bearer ' + userData.token,
    //     }
    // })

    //     .then((response) => {

    //         //////console.log("notificationdetail", response.data)
    //         const NotificationDetail2 = response.data
    //         //////console.log("********", NotificationDetail2)
    //         setNotifications(NotificationDetail2)

    //     })
    //     .catch((error) => {

    //     })

    const fetchData = async () => {
      setModalText(notifications);

      // setLoading(false);
    };
    if (modalOpen) {
      fetchData();
    }

    //////////////////Notification number//////////////

    // axios({
    //     method: 'GET',

    //     url: baseurl + '/api/notification/getnumber?client_id=' + userData.clientId,

    //     headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: 'Bearer ' + userData.token,
    //     }
    // })

    //     .then((response) => {

    //         //////console.log("notification", response.data)
    //         const NotificationData1 = response.data
    //         const NotificationData = NotificationData1.number
    //         setNotificationnumber(NotificationData)

    //     })
    //     .catch((error) => {

    //         //    //////console.log(error)

    //     })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    //To set First Time Page Name
    if (userData.Pagename == undefined) {
      dispatch(
        updateLoginStatus({
          isLoggedIn: true,
          Pagename: userData.Pagename,
          userName: userData.userName,
          userId: userData.userId,
          role: userData.role,
          token: userData.token,
          projectId: userData.projectId,
          clientId: userData.clientId,
          currentprojectId: userData.currentprojectId,
          dropdownOption: "Project Timeline",
          projectType: userData.projectType,
          companyName: userData.companyName,
          projectName: userData.projectName,
          projectMenuIndex: 0,
          resourceMenuKey: "material",
          modalNotificationstatus: false,
          profileImage: userData.profileImage,
          headerMenus: userData.headerMenus,
          projectMenus: userData.projectMenus,
          sidebartoggleStatus: userData.sidebartoggleStatus,
          loginUsername: userData.loginUsername,
          engineerLoginStatus: false,
          statename: userData.statename,
          circle: userData.circle,
          division: userData.division,
          subdivision: userData.subdivision,
        })
      );
    }
  }, [userData.Pagename, modalOpen, userData.modalNotificationstatus]);

  const [linkname, setLinkname] = useState("");

  // function updatelinkname(menuname) {

  //     setActiveMenu(menuname)

  //     //Page name update
  //     dispatch(
  //         updateLoginStatus({
  //             isLoggedIn: true,
  //             Pagename: menuname,
  //             userName: userData.userName,
  //             userId: userData.userId,
  //             role: userData.role,
  //             token: userData.token,
  //             projectId: userData.projectId,
  //             clientId: userData.clientId,
  //             profileImage: userData.profileImage,
  //             headerMenus: userData.headerMenus,
  //             projectMenus: userData.projectMenus,
  //             sidebartoggleStatus: userData.sidebartoggleStatus
  //         })
  //     )
  // }

  function logoutfunc(e) {
    //Logout
    axios({
      method: "PUT",
      url: baseurl + "/api/logout?user_id=" + userData.userId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {})
      .catch((error) => {
        //////console.log(error.response.data.message)
      });
    dispatch(
      updateLoginStatus({
        isLoggedIn: false,
        //  isLoggedInDemoUser: false,
      })
    );
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <div className="headersizedivnew ">
      {userData.modalNotificationstatus && request === "Site Update" && (
        <ButtonLink btnPath="/Road" />
      )}
      {userData.modalNotificationstatus && request != "Site Update" && (
        <ButtonLink btnPath="/Dashboard" />
      )}
      <div className="">
        <nav className="navbar navbar-expand-xl gradient-bg  p-0">
          <div className="container-fluid d-flex justify-content-between px-0">
            {/* <div className=""> */}
            {/* <center>
                                <img src={logo1} alt="not found" class="rounded" 
                                width={200} height={75} 
                                />
                            </center> */}
            {/* <div className="image-with-shadow">
                                <div className="image-container-brick" style={{ backgroundImage: `url(${logo1})` }}>

                                </div>
                            </div> */}
            {/* </div> */}
            <div>
              {/* <button className="navbar-toggler expandbtn" style={{color:'#000000'}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent1" aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                                <center className='padlh'> <span className="navbar-toggler-icon "style={{color:'#000000'}}></span></center>
                            </button> */}
            </div>

            <header className="header headpad w-100">
              <div
                className=" collapse navbar-collapse d-flex justify-content-between align-items-center  "
                id="navbarSupportedContent1"
              >
                <img
                  src={logo1}
                  width={140}
                  className="rounded headlogo"
                  style={{ border: "1px solid #f6fa00" }}
                />
                <ul className="mb-0 me-auto d-flex align-items-center  p-0">
                  <li>
                    <img
                      src={userData.profileImage}
                      width={55}
                      className="profimg  "
                    />
                  </li>
                  <li className="ml-2 ">
                    <div
                      className="d-flex flex-column   namewid "
                      style={{ width: "100px" }}
                    >
                      <font
                        className=" text-white namefont"
                        style={{ fontSize: "14px" }}
                      >
                        <b> {userData.userName}</b>{" "}
                      </font>

                      <font
                        className="text-white namefont"
                        style={{ fontSize: "14px" }}
                      >
                        {userData.role}
                      </font>
                    </div>
                  </li>
                  <div className="menu1">
                    <ul className="nav-icon1 d-flex align-items-center p-0 ">
                      <li className="">
                        <center>
                          <Link className="nav-link disablepointer p-0 pr-2">
                            <div
                              className="numbered-image-container"

                              // onClick={openModal}
                            >
                              <img
                                src={notification}
                                className="icon1st-new "
                              />
                              {notificationnumber != 0 && (
                                <div className="number-overlay">
                                  {notificationnumber}
                                </div>
                              )}
                              {notificationnumber == 0 && <div>{}</div>}
                            </div>
                            {notifications.length != 0 && (
                              <Model1
                                isOpen={modalOpen}
                                onClose={closeModal}
                                children1={notifications}

                                // notiupdate={notiupdate}
                              />
                            )}
                          </Link>
                        </center>
                      </li>
                      <li className="">
                        <center>
                          <Link onClick={(e) => logoutfunc(e)}>
                            <img src={logout} className="icon1st-new " />
                          </Link>
                        </center>
                      </li>
                    </ul>
                  </div>
                </ul>
              </div>

              <button className="text-white btn p-0 menu" onClick={toggleMenu}>
                {isOpen ? <RxHamburgerMenu /> : <RxHamburgerMenu />}
              </button>
              <nav className={isOpen ? "nav-menu open p-0" : "nav-menu p-0"}>
                <div>
                  <ul className="nav-icon1">
                    <li className="">
                      <center>
                        <Link className="nav-link disablepointer">
                          <div
                            className="numbered-image-container"

                            // onClick={openModal}
                          >
                            <img src={notification} className="icon1st-new " />
                            {notificationnumber != 0 && (
                              <div className="number-overlay">
                                {notificationnumber}
                              </div>
                            )}
                            {notificationnumber == 0 && <div>{}</div>}
                          </div>
                          {notifications.length != 0 && (
                            <Model1
                              isOpen={modalOpen}
                              onClose={closeModal}
                              children1={notifications}

                              // notiupdate={notiupdate}
                            />
                          )}
                        </Link>
                      </center>
                    </li>
                    <li className="">
                      <center>
                        <Link onClick={(e) => logoutfunc(e)}>
                          <img src={logout} className="icon1st-new " />
                        </Link>
                      </center>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>

            {/* {userData.projectName!=undefined &&
                            userData.projectName!="" &&    
                            
                            <div className={headMenuList.length>4 ? 'projectnamestylesmallgap':'projectnamestylelonggap'}> 
                            <center>
                          <font><b> { userData.projectName}</b></font> 
                            </center>
                            </div>} */}
          </div>
        </nav>
      </div>
    </div>
  );
}
