import React from "react";
import RadialBar from "../ApexCharts/RadialBar";
import "./Engineerlogin.css";
import user from "../../assets/brickdemoicons/user.png";
import lock from "../../assets/brickdemoicons/lock.png";
import enterpass from "../../assets/brickdemoicons/enter password.jpg";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import iconimg from "../../assets/images1/Projectstatus.png";
import completed from "../../assets/images/completed-5.png";
import inprogress from "../../assets/images/buttons/inprogress.png";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import ButtonLink from "../ButtonLink/ButtonLink";
// import banner from '../../assets/images1/backgroundabstractwhite.png'
import search from "../../assets/images1/Vector.png";
import { AiFillEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CustomToastContainer from "../../CustomToastContainer";

const WeareHere = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [structureName, setStructureName] = useState("");
  const [structureId, setStructureId] = useState("");
  const [itemName, setItemName] = useState("");
  const [compDate, setCompDate] = useState("");
  const [itemOfWork, setItemOfWork] = useState("");
  const [fulldata, setFulldata] = useState([]);
  const [btnname, setBtnname] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [caxis, setCaxis] = useState([]);
  const [daxis, setDaxis] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState("");
  const [graphform, setGraphform] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [passworderror, setPassworderror] = useState();
  const [passwordValid, setPasswordValid] = useState();
  const [usernameValid, setusernameValid] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [successstatus, setSuccessstatus] = useState("");
  const [uname, setUname] = useState("");
  const [userid, setUserid] = useState("");

  const [role, setRole] = useState("");
  const [token, setToken] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [clientid, setClientid] = useState("");
  const [messageList, setMessageList] = useState([]);

  const userData = useSelector((state) => state.userDetails);

  const [list, setList] = useState([]);
  const [id, setId] = React.useState("");
  const [status, setStatus] = useState(false);
  const [projecttype, setProjecttype] = React.useState("");
  const [projectname, setProjectname] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // axios({
    //   method: 'GET',

    //   url: baseurl+'/api/project/list/get?client_id='+userData.clientId,

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token
    //   }
    // })

    //   .then((response) => {

    //     //console.log(response.data)
    //     const RequestData = response.data
    //     // setRequestdata1(RequestData)
    //     //console.log("request",RequestData)

    //  setList(RequestData)

    //   })
    //   .catch((error) => {

    //     //console.log(error)
    //     // setErrormsg(error.response.data.message)
    //   })

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const togglePasswordVisiblity = (event) => {
    setIsPasswordShown(!isPasswordShown);
    event.preventDefault();
  };
  function onpasswordChange(e) {
    setPassword(e.target.value.replace(/\s+/g, ""));
    validateField("password", e.target.value);
  }
  function onemailChange(e) {
    setUsername(e.target.value.replace(/\s+/g, ""));
    validateField("username", e.target.value);
  }

  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})"
  );

  const validateField = (fieldName, value) => {
    if (fieldName === "password") {
      let passwordValid = value != "" && value.match(strongRegex);

      setPasswordValid(passwordValid);

      if (passwordValid === null || passwordValid === undefined) {
        setPassworderror(
          "Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character"
        );
        setPasswordValid(false);
      } else {
        setPassworderror("");
        setPasswordValid(true);
      }
    }
    if (fieldName === "username") {
      if (value.length == 0) {
        setUsernameError("Please Enter your valid Username");
        setusernameValid(false);
      } else {
        setUsernameError("");
        setusernameValid(true);
      }
    }
  };

  function onSubmitEvent(event) {
    setMessageList([]);

    event.preventDefault();

    if (passwordValid) {
      let formd = new FormData();
      formd.append("username", userData.loginUsername);
      formd.append("password", password);
      formd.append("is_engg_login", 1);
      axios
        .post(baseurl + "/api/login", formd, {
          headers: { "Content-Type": "multipart/form-data" },
        })

        .then((response) => {
          //console.log(response.data)

          const data = response.data;

          setUname(data.name);
          setUserid(data.userid);
          setToken(data.token);
          setRole(data.role);
          setProjectid(data.project_id);
          setClientid(data.client_id);
          setStatus(true);
          onEngineerloginStatusUpdate(data.token);
        })
        .catch((error) => {
          //console.log(error)
          //console.log(error.response.data.message)
          // setErrormsg(error.response.data.message)
          const pwd_err = error.response.data.message;
          setMessageList((prevMessages) => [...prevMessages, pwd_err]);
        });
    }
  }

  function onEngineerloginStatusUpdate(tok) {
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        engineertoken: tok,
        projectId: userData.projectId,
        companyName: userData.companyName,
        clientId: userData.clientId,
        // currentprojectId:pid,
        // dropdownOption:'Project Timeline',
        // projectType:ptype,
        projectName: userData.projectName,
        projectMenuIndex: userData.projectMenuIndex,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebarReportsMenus: userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus: userData.sidebarWorkDetailsMenus,
        dropdownViewStatus: userData.dropdownViewStatus,
        chainageArr: userData.chainageArr,
        projectStatus: true,
        projectsubname: userData.projectsubname,
        loginUsername: userData.loginUsername,
        engineerLoginStatus: true,
        searchParams: userData.searchParams,
        statename: userData.statename,
        circle: userData.circle,
        division: userData.division,
        subdivision: userData.subdivision,
      })
    );
  }

  return (
    <div className="cont-margin1 ">
      {/* {status && projecttype==='Building' &&<ButtonLink id={id} btnPath="/Building" />}
        {status && projecttype==='Road' &&<ButtonLink id={id} btnPath="/Road" />} */}

      {status && <ButtonLink id={id} btnPath="/EngineerPage" />}
      <div class="cont-margin-latest mt-3 pt-5 pl-3">
        <div className="container-fluid ">
          <div className=" mx-auto s pb-4  bg-white mt-4  ">
            <h4
              className="text-center engfonsiz1"
              style={{
                fontFamily: "plus jakarta sans",
                color: "#BB1245",
                fontWeight: "600",
                color: "blue",
              }}
            >
              ENGINEER LOGIN
            </h4>
            <div>
              <div className="text-center ">
                <img className="mx-auto " src={enterpass} width={180} />
              </div>

              <div
                class="input-group text-center mx-auto col-10 col-sm-5 col-lg-3 col-xl-3 col-md-5 px-0"
                style={{
                  borderRadius: "6px",
                  border: "1px solid #C0C0C0",
                 
                }}
              >
                <input
                  className="form-control border-0 bg-transparent  pl-5"
                  placeholder="Password"
                  value={password}
                  onChange={onpasswordChange}
                  type={isPasswordShown ? "text" : "password"}
                  style={{
                    backgroundImage: `url(${lock})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "15px",
                    width: "45px",
                  }}
                />
                <div class="input-group-prepend">
                  <div
                    class="input-group-text border-0 bg-transparent"
                    id="basic-addon1"
                  >
                    {" "}
                    {isPasswordShown ? (
                      <AiFillEye
                        className=""
                        onClick={togglePasswordVisiblity}
                        size={20}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        className=""
                        onClick={togglePasswordVisiblity}
                        size={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className="login-error mx-auto"
                style={{ height: "50px", fontSize: "12px", width: "75%" }}
              >
                {passworderror}
                <br />
              </div>
            </div>
            <div className="text-center">
              <CustomToastContainer messageList={messageList} />

              <button
                className="btn mt-3  mx-auto text-white"
                style={{
                  background: "linear-gradient(to right,#0ECD67,#00A151)",
                  borderRadius: "12px",
                  fontSize: "16px",
                  width: "100px",
                  height: "45px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                }}
                onClick={onSubmitEvent}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeareHere;
