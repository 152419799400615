import React, { useState, useEffect } from "react";
import "./ContractDetails1.css";
import img from "../../assets/brickdemoicons/pdficon.png";
import { Link } from "react-router-dom";
import arrow from "../../assets/brickdemoicons/back.png";
import DocumentView from "../../Component/Modal/DocumentView";
import { connect, useDispatch, useSelector } from "react-redux";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";

function ContractorDetails() {

  const [areContractorDetailsLoading, setAreContractorDetailsLoading] =
    useState(true);
  const [contractorDetailsList, setContractorDetailsList] = useState([]);
  const userData = useSelector((state) => state.userDetails);
  const [modalStatus, setModalStatus] = useState(false);
  const [agreement, setAgreement] = useState('');
  
  const tocloseModal = () => {
    setModalStatus(false);
  };
  function documentClick() {
    setModalStatus(true);
  }

  useEffect(() => {
    axios
      .get(`${baseurl}/api/vendor/list/get/vendor_details`, {
        params: {
          project_id: userData.projectId,
          contractor_type: "Contractor",
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const contractorDetails = data.contract_details || [];
        console.log(JSON.stringify(contractorDetails))
        let contractorid=contractorDetails[0].contractor_id
        getdocument(contractorid)
        if (contractorDetails.length !== 0) {
          setContractorDetailsList(contractorDetails);


        }
        setAreContractorDetailsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });


      


    

  }, []);

  function getdocument(cid)
  {
    
    axios
    .get(`${baseurl}/api/vendor/get/condition_of_agreement?project_id=`+userData.projectId, {
     
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userData.token}`,
      },
    })
    .then((response) => {
      const data = response.data;
      if(data!=null && data!=undefined && data!='')
      {
        
      const byteCharacters = atob(data); // Base64 decode if required
      const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setAgreement(url)
      }
     
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });


  }
  return (
    <div
      className="container-fluid w-100 cont-margin2 mx-auto p-0"
      style={{
        position: "absolute",
        top: "80px",
        left: "55%",
        transform: "translate(-50%,5%)",
      }}
    >
      <div className="row w-75  mb-0 mx-auto">
        <div className="col-12 text-center">
          <h6 className=" text-danger ">{userData.projectName}</h6>
        
        </div>
      </div>
      <div className="  contwid border mx-auto pb-3 rounded shadow">
        <div className="rounded-top " style={{ background: "linear-gradient(to right,#4889CF,#144A9A)" }}>
          <h6 className="text-center mb-0 conthead p-2 text-white">Contractor Details</h6>
        </div>
        <table className="table table-borderless m-0 mx-auto rounded-bottom center-spinner">
          {areContractorDetailsLoading && (
            <ThreeDots
              visible={true}
              height="20"
              width="40"
              color="#3369a6"
              radius="7"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}

          {!areContractorDetailsLoading &&
            contractorDetailsList.length === 0 && (
              <tbody className="w-100 text-center ">No Data Available</tbody>
            )}

          {!areContractorDetailsLoading &&
            contractorDetailsList.length !== 0 && (
              <tbody className="w-100 contwid margcont" style={{ marginLeft: "45px" }}>
                {contractorDetailsList.map((contractor, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <th className="th ">Company Name </th>{" "}
                    
                      <td className=" td my-auto" style={{ color: "#095091" }}>
                      :&nbsp;&nbsp;&nbsp;{contractor.vendor_company_name}
                      </td>
                    </tr>
                    <tr>
                      <th className="th ">Agreement Date </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.onboarding_date}</td>
                    </tr>
                    <tr>
                      <th className="th ">Contact Person </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.contact_person}</td>
                    </tr>
                    <tr>
                      <th className="th ">Contact Mail </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.email}</td>
                    </tr>
                    <tr>
                      <th className="th ">Phone Number </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.phone_no}</td>
                    </tr>
                    <tr>
                      <th className="th ">Contract Code  </th>{" "}
                     
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.contractor_code}</td>
                    </tr>
                    <tr>
                      <th className="th ">Organization Address  </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.organization_address}</td>
                    </tr>
                    <tr>
                      <th className="th ">Firm Details  </th>{" "}
                     
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.firm_details}</td>
                    </tr>
                    <tr>
                      <th className="th ">Pan No.   </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.pan_no}</td>
                    </tr>
                    <tr>
                      <th className="th ">Aadhar Card  </th>{" "}
                     
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.aadhaar_no}</td>
                    </tr>
                    <tr>
                      <th className="th ">Registration No.  </th>{" "}
                    
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.contractor_id}</td>
                    </tr>
                    <tr>
                      <th className="th ">GST No.  </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.gst_no}</td>
                    </tr>
                    <tr>
                      <th className="th ">CIN No.  </th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;{contractor.cin_no}</td>
                    </tr>
                    <tr>
                      <th className="th ">Condition of Agreement</th>{" "}
                      
                      <td className=" td"> :&nbsp;&nbsp;&nbsp;
                   {contractor.contract_document_image!='-'   ?  <button
                          onClick={documentClick}
                          className="custom-btn-view "
                        >
                          <img src={img}  alt="img"  />
                          &nbsp; View
                        </button>:<span >NA</span>}

                      </td>
                      {modalStatus && agreement!='' &&(
                        <DocumentView
                          status={modalStatus}
                          btnClick={tocloseModal}
                          imageUrl={agreement}
                          imageData={contractor.contract_document_image
                            .replace(/width\s*=\s*['"]600['"]/g, 'width="400"')
                            .replace(
                              /height\s*=\s*['"]auto['"]/g,
                              'height="510"'
                            )}
                        />
                      )}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
        </table>
      </div>
    </div>
  );
}

export default ContractorDetails;
