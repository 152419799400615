// Legend.js
import React from 'react';
import './LegendNames.css'; // Create this file for styling the legend

const Legend = () => {
  return (
    <div className="legend " style={{fontSize:'10px'}}>
      <h6>Irukkankudi Project</h6>
      <ul>
        <li className='logsiz'><span className="legend-color  green"></span> Completed</li>
        <li><span className="legend-color  yellow"></span> InProgress</li>
        <li><span className="legend-color  red"></span> Pending</li>
       
      </ul>
    </div>
  );
};

export default Legend;
