import React, { useEffect, useState } from "react";
import "./WorkDetails.css";
import Methodology from "./Methodology";
import arrow from "../../assets/brickdemoicons/back.png";
import methodImg from "../../assets/brickdemoicons/methodImg.png";
import tdImg from "../../assets/brickdemoicons/tdImg.png";
import boqImg from "../../assets/brickdemoicons/boqImg.png";
import galleryImg from "../../assets/brickdemoicons/galleryImg.png";
import methodImg1 from "../../assets/brickdemoicons/methodImg2.png";
import tdImg1 from "../../assets/brickdemoicons/tdImg2.png";
import boqImg1 from "../../assets/brickdemoicons/boqImg2.png";
import galleryImg1 from "../../assets/brickdemoicons/galleryImg2.png";
import arrow1 from "../../assets1/images/Arrow 1.png";
import TestingDependency from "./TestingDependency";
import Boq from "./Boq";
import Gallery from "./Gallery";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";
function WorkDetails() {
  const [method, setMethod] = useState(true);
  const [td, setTd] = useState(false);
  const [boq, setBoq] = useState(false);
  const [gallery, setGallery] = useState(false);
  const userData = useSelector((state) => state.userDetails);

  const handleMethodClick = () => {
    setMethod(true);
    setTd(false);
    setBoq(false);
    setGallery(false);
  };

  const handleTdClick = () => {
    setMethod(false);
    setTd(true);
    setBoq(false);
    setGallery(false);
  };

  const handleBoqClick = () => {
    setMethod(false);
    setTd(false);
    setBoq(true);
    setGallery(false);
  };

  const handleGalleryClick = () => {
    setMethod(false);
    setTd(false);
    setBoq(false);
    setGallery(true);
  };

  const [selectedItem, setSelectedItem] = useState("Methodology");

  const tabsm = [
    "Methodology",
    "Testing Dependency",
    "Bill Of Quantity",
    "Gallery",
  ];

  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      <div
        className="container-fluid bg-white pb-1 px-0 wd-cnt"
        style={{
          // position: "absolute",
          // zIndex: "99",
          // top: "180px",
          // left: "55%",
          // transform: "translate(-50%,-50%)",
          marginTop: "80px",
          marginLeft: "60px",
        }}
      >
        <div
          className=" w-100  pt-2 d-flex justify-content-center
         align-items-center mb-4 pb-1 "
        >
          <div className="w-100 mb-0 d-flex align-items-center justify-content-center ">
            <h5 className="namsiz mb-0">{userData.projectName}</h5>
            <img
              className="arrsiz mb-0 ml-2"
              src={arrow1}
              width={100}
              height={10}
            />
            <h6 className=" namsiz ml-2 mb-0">
              Total Chainage
              <span
                className="ml-1"
                style={{ color: "#0AAF4C", fontWeight: "600" }}
              >
                1/000 - 6/000
                {/* {userData.startChainage}-{userData.endChainage} */}
              </span>
            </h6>
          </div>
        </div>
        <div className="d-sm-none d-flex w-100 justify-content-center">
          <DropdownButton 
            className="dropdown-toggle1"
            id="dropdown-basic-button"
            title={selectedItem}
          >
            {tabsm.map((tabname,index)=>{
              return(
                <Dropdown.Item key={index} onClick={() => handleSelect(tabname)}
                  >
                  {tabname}
                </Dropdown.Item>
              )
            })}            
          </DropdownButton>
        </div>
        <div
          className="d-none d-sm-flex menusiz  justify-content-center mx-auto  p-0 rounded "
          style={{
            backgroundColor: "#1C434D",
            height: "50px",
            position: "relative",
            width: "550px",
          }}
        >
          <div className=" text-center ">
            <div
              className="mr-4 "
              onClick={handleMethodClick}
              style={{ cursor: "pointer" }}
            >
              <div
                className={`${method ? "tabMenu mx-auto  pt-0 pt-md-2" : " "}`}
              >
                <img
                  className="methodico "
                  src={method ? methodImg1 : methodImg}
                  width={20}
                  alt="icon"
                />
              </div>
              <label
                style={{
                  fontSize: "12px",
                  color: method ? "#ffb700" : "white",
                }}
                className={`${
                  method
                    ? "form-label mb-0 pt-3 namsiz1  namsiz2"
                    : " pt-0 namsiz1  "
                }`}
              >
                Methodology
              </label>
            </div>
          </div>
          <div className="text-center">
            <div
              className="ml-4 namsiz3"
              onClick={handleTdClick}
              style={{ cursor: "pointer" }}
            >
              <div className={`${td ? "tabMenu1 pt-0 pt-md-2" : ""}`}>
                <img
                  className="methodico"
                  src={td ? tdImg1 : tdImg}
                  width={20}
                  alt="icon"
                />
              </div>

              <label
                style={{ fontSize: "12px", color: td ? "#ffb700" : "white" }}
                className={`${
                  td
                    ? "form-label mb-0  mt-4 pt-1 namsiz1 namsiz3"
                    : " pt-0 namsiz1"
                }`}
              >
                Testing Dependency
              </label>
            </div>
          </div>
          <div className=" ml-5 text-center">
            <div onClick={handleBoqClick} style={{ cursor: "pointer" }}>
              <div className={`${boq ? "tabMenu2 pt-0 pt-md-2" : ""}`}>
                <img
                  className="methodico"
                  src={boq ? boqImg1 : boqImg}
                  width={20}
                  alt="icon"
                />
              </div>

              <label
                style={{ fontSize: "12px", color: boq ? "#ffb700" : "white" }}
                className={`${
                  boq
                    ? "form-label mb-0 mt-4 pt-1 namsiz1 namsiz4"
                    : " pt-0 namsiz1"
                }`}
              >
                Bill Of Quantity
              </label>
            </div>
          </div>
          <div className=" ml-5 text-center">
            <div onClick={handleGalleryClick} style={{ cursor: "pointer" }}>
              <div className={`${gallery ? "tabMenu3 pt-0 pt-md-2" : ""}`}>
                <img
                  className="methodico"
                  src={gallery ? galleryImg1 : galleryImg}
                  width={20}
                  alt="icon"
                />
              </div>

              <label
                style={{
                  fontSize: "12px",
                  color: gallery ? "#ffb700" : "white",
                }}
                className={`${
                  gallery ? "form-label mb-0 mt-4 pt-1 namsiz1" : "pt-0 namsiz1"
                }`}
              >
                Gallery
              </label>
            </div>
          </div>
        </div>
        <div className=" mx-auto">
          {method && <Methodology />}
          {td && <TestingDependency />}
          {boq && <Boq />}
          {gallery && <Gallery />}
        </div>
      </div>
    </>
  );
}

export default WorkDetails;
