import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Form } from "react-bootstrap";
import "./Modal.css";
import CreateRequest from "../CreateRequest/CreateRequest";
import { useState } from "react";
import InputTextField from "../InputText/InputText";
import axios from "axios";
import { baseurl } from "../BaseUrl/Baseurl";
import { connect, useDispatch, useSelector } from "react-redux";
import { IoMdClose  } from "react-icons/io";
export default function Model({ btnClick, status, TicketNumber }) {
  const [projectstatus, setProjectstatus] = useState("");
  const [projectstatusValid, setProjectstatusValid] = useState(false);
  const [projectstatuserror, setProjectstatuserror] = useState("");
  const [modalVisible, setModalVisible] = useState(status);
  const [ticketlist1, setTicketlist1] = useState([]);
  const [message, setMessage] = useState("");
  const [successmsg, setSuccessmsg] = useState("");

  const userData = useSelector((state) => state.userDetails);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    //console.log(TicketNumber)
    let userData = new FormData();
    userData.append("ticket_number", TicketNumber);
    axios({
      method: "GET",

      url:
        baseurl +
        "/api/support/issue/ticket/list" +
        "?ticket_number=" +
        TicketNumber,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
      data: userData,
    })
      .then((response) => {
        //console.log("***************")

        //console.log("hello" + response.data)
        const TicketData = response.data;
        //console.log(TicketData)
        setTicketlist1(TicketData);
        // ////alert(JSON.stringify(TicketData))
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      });
  }, [status, modalVisible]);

  const validateField = (fieldName, value) => {};
  function handlemessage(e) {
    setSuccessmsg("");
    //console.log(message)

    setMessage(e.target.value.replace(/\s+/g, ""));

    validateField(e.target.name, e.target.value);
  }
  function onSubmitEvent(event) {
    event.preventDefault();

    let userData = new FormData();
    userData.append("ticket_number", TicketNumber);
    userData.append("support_status_comment", message);
    userData.append("support_status", ticketlist1.support_status);
    axios({
      method: "PUT",

      url: baseurl + "/api/support/issue/ticket/update/status",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
      data: userData,
    })
      .then((response) => {
        //console.log(response.data)
        // ////alert("success")
        setSuccessmsg("Successfully Updated");
      })
      .catch((error) => {
        //console.log(error)
        // setErrormsg(error.response.data.message)
      });
    setModalVisible(false);
  }

  return (
    <>
      <Modal show={modalVisible} onHide={btnClick} id="" className="">
        <Modal.Body className="bg-white">
          <div className="model-top d-flex justify-content-between">
            <center>View Ticket Details</center>
            <button onClick={()=> btnClick()} className="btn"><IoMdClose /></button>
          </div>

          {/* <CreateRequest /> */}

          <div className="">
            <div class="row pb-5">
              <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                <form
                  class="row d-flex justify-content-center align-center  blacktext "
                  onSubmit={(event) => {
                    onSubmitEvent(event);
                    btnClick(); // Close the modal
                  }}
                >
                  <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                    <label for="formFileLg" className="login-text">
                      Ticket ID
                    </label>
                    <center>
                      <InputTextField
                        //  handleInputChange={handleContactperson}
                        //  valueText={contactperson}
                        //  inputName="contactperson"
                        //  inputId="contactperson"
                        classname="project-box"
                        //  maxLen={20}
                        inputType="text"
                        placeholderValue={TicketNumber}
                        disabled
                      ></InputTextField>
                    </center>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                    <label for="formFileLg" className="login-text">
                      Discription
                    </label>
                    <center>
                      <InputTextField
                        //  handleInputChange={handleContactperson}
                        //  valueText={contactperson}
                        //  inputName="contactperson"
                        //  inputId="contactperson"
                        classname="project-box"
                        //  maxLen={20}
                        inputType="text"
                        placeholderValue={ticketlist1.issue_description}
                        disabled
                      ></InputTextField>
                    </center>
                  </div>

                  <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                    <label for="formFileLg" className="login-text">
                      Raised By
                    </label>
                    <center>
                      <InputTextField
                        //  handleInputChange={handleContactperson}
                        //  valueText={contactperson}
                        //  inputName="contactperson"
                        //  inputId="contactperson"
                        classname="project-box"
                        //  maxLen={20}
                        inputType="text"
                        placeholderValue={ticketlist1.raised_by}
                        disabled
                      ></InputTextField>
                    </center>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                    <label for="formFileLg" className="login-text">
                      Status
                    </label>
                    <center>
                      <InputTextField
                        //  handleInputChange={handleContactperson}
                        //  valueText={contactperson}
                        //  inputName="contactperson"
                        //  inputId="contactperson"
                        classname="project-box"
                        //  maxLen={20}
                        inputType="text"
                        placeholderValue={ticketlist1.support_status}
                        disabled
                      ></InputTextField>
                    </center>
                  </div>
                  <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                    <label for="formFileLg" className="login-text">
                      Valid Date
                    </label>
                    <center>
                      <InputTextField
                        //  handleInputChange={handleContactperson}
                        //  valueText={contactperson}
                        //  inputName="contactperson"
                        //  inputId="contactperson"
                        classname="project-box"
                        //  maxLen={20}
                        inputType="text"
                        placeholderValue={ticketlist1.raised_on}
                        disabled
                      ></InputTextField>
                    </center>
                  </div>

                  <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
                    <center>
                      <div class="textareamargin">
                        <textarea
                          class="form-control"
                          id="message"
                          name="message"
                          rows="3"
                          placeholder="Message"
                          onChange={handlemessage}
                        ></textarea>
                      </div>
                    </center>
                  </div>
                  <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                    <center>
                      <div className="success">{successmsg}</div>
                      <button
                        type="submit"
                        className="project-btn"
                        name="submit"
                        // disabled={!(projectstatusValid )}
                      >
                        <b>Submit</b>
                      </button>
                    </center>{" "}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
