import React from "react";
import RadialBar from "../ApexCharts/RadialBar";
import "./Support.css";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import RequestModal from "../Modal/CreateRequest";
import StatusupdateModal from "../Modal/Edit";
import Renewal from "../Modal/Renewal";
import ViewTickets from "../Modal/ViewTickets";
import { baseurl } from "../BaseUrl/Baseurl";

import axios from "axios";
import close from "../../assets/images/images/closed.png";
import open from "../../assets/images/images/open.png";
import $ from "jquery";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";

const WeareHere = () => {
  const [btnname, setBtnname] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState("");
  const [project_renewalid, setProject_renewalid] = useState("");
  const [ticketno, setTicketno] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const userData = useSelector((state) => state.userDetails);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios({
      method: "GET",

      url: baseurl + "/api/support/issue/ticket/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        //console.log(response.data)
        const TicketData = response.data;
        setTicketlist(TicketData);
        setSuccessstatus(true);
      })
      .catch((error) => {
        //console.log(error)
      });
  }, [modelStatus, updatestatus]);

  const toggleModelStatus = () => {
    setRequeststatus(false);
  };
  const toggleModelStatus1 = () => {
    setUpdatestatus(false);
  };
  const toggleRenewalStatus = () => {
    //console.log("***********")
    setRenewalstatus(false);
  };
  const toggleViewTicketStatus = () => {
    //console.log("***********")
    setViewticketstatus(false);
  };
  const requestdata = () => {
    //console.log("*****" + requeststatus)
    setRequeststatus(true);
  };
  const Editdata = (id) => {
    setProjectid(id);
    setUpdatestatus(true);
  };
  const RenewalUpdate = (id) => {
    setProject_renewalid(id);
    setRenewalstatus(true);
  };
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };

  return (
    <>
      <div className="container px-0 w-100 mx-auto pt-5 " >
        <div class="row ml-5 pt-5">
          <div class=" col-xl-2 pt-1 pb-1"></div>
          <div class=" col-xl-4  pb-1 pt-0">
            <div className="padheadleft ff pt-0 pl-0 text-center pt-2">
              <b>Inspection Schedule</b>
            </div>
          </div>
          {/* <div class=" col-xl-4 pt-1 pb-1"></div> */}
          <div class=" col-xl-4 pt-1 pb-1  text-center">
            <Link to="/SupportCreation">
              <button type="button" className="createpro-btn ml-0 ">
                &nbsp;Create Ticket
              </button>
            </Link>
          </div>
          <div class=" col-xl-2 pt-1 pb-1"></div>

{ticketlist.length == 0 && successstatus && (
        // !checkduedate &&
        <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3 pt-3">
          <center>
            <b>
              <h6>No Data Available</h6>
            </b>
          </center>
        </div>
      )}
      {ticketlist.length != 0 && (
        <div className="w-75 mx-auto px-0 table-responsive col-12">
        <table class="table w-75 mx-auto" readOnly="readOnly">
          {/* <div class=" col-xl-12 pb-3 pt-0 pb-0 ">
            <Link to="/SupportCreation"> 
            <button 
                    type="button"
                    className="createpro-btn0"
                  >
                   &nbsp;Create</button>
            </Link> 
              </div> */}
          <tr class="trst">
            <th className=" text-nowrap">Project ID</th>
            {/* <th><center>Contact</center></th> */}
            <th className="">Ticket Number</th>
            <th className="">Status</th>
            <th className="">Tickets</th>
          </tr>
          {viewticketstatus && (
            <ViewTickets
              status={viewticketstatus}
              btnClick={toggleViewTicketStatus}
              TicketNumber={ticketno}
            />
          )}
          {ticketlist.length != 0 &&
            ticketlist.map((item, index) => {
              return (
                <tr key={index} className="">
                  <td>
                    {item.project_id != null && item.project_id != undefined
                      ? item.project_id
                      : "NA"}
                  </td>
                  {/* <td><center>{item.contact_person}</center></td> */}
                  <td className="text-nowrap">{item.ticket_number}</td>
                  <td>
                    {item.request_status === "close" ? (
                      <img src={close} width={130} className="ml-0 " />
                    ) : (
                      <img src={open} width={130} className="ml-0 " />
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="viewtic-btn1 "
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View</b>
                    </button>
                  </td>
                </tr>
              );
            })}
        </table>
        </div>
      )}

        </div>
        

      </div>

     
    </>
  );
};

export default WeareHere;
