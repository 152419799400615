import React, { useEffect, useState } from "react";
import { RxSlash } from "react-icons/rx";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomToastContainer from "../../CustomToastContainer";
import ReactPaginate from "react-paginate";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

function Boq() {
  const [tableShow, setTableShow] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [err, setErr] = useState("");
  const userData = useSelector((state) => state.userDetails);
  const [searchData, setSearchData] = useState([]);
  const [filteredChainage, setFilteredChainage] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Set items per page

  // Reset to the first page whenever searchData changes
  useEffect(() => {
    setCurrentPage(0);
  }, [searchData]);

  // Calculate current items to display based on pagination
  const offset = currentPage * itemsPerPage;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, searchData?.length || 0);
  const currentItems = searchData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(searchData.length / itemsPerPage);

  // Handle page click
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [digiter, setDigiter] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);

    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setSuccessstatus(false);
      setTypeOfWork([]);
      setStructure([]);
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));

      setStructure([]);
      setSuccessstatus(false);
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setSuccessstatus(false);
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "" && formData.to != "") {
      if (e.target.name === "to1") {
        axios
          .get(`${baseurl}/api/work_details/get/type_of_work`, {
            params: {
              project_id: userData.projectId,
              start_chainage: `${formData.from}.${formData.from1}`,
              end_chainage: `${formData.to}.${e.target.value}`,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => {
            const data = response.data;
            const type_of_work_list = data.type_of_work_list;
            setTypeOfWork(type_of_work_list);
            // //////alert("type of work", type_of_work_list);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          ////console.log(strs_or_layers_list);
          // //////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setSearchData([]);
  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios
        .get(`${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=boq&`, {
          params: {
            project_id: userData.projectId,
            strs_or_layers: formData.structure,
            type_of_work: formData.type,
            start_chainage: `${formData.from}.${formData.from1}`,
            end_chainage: `${formData.to}.${formData.to1}`,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data.str_or_layer_ids_data;
          setSuccessstatus(true);
          ////console.log(data);
          setSearchData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      setTableShow(true);
      setErr("");
    }
  };

  const handleSubmit = (e) => {
    setMessageList([]);

    e.preventDefault();
    validate();
  };

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className=" method-cont mx-auto w-75 "
        style={
          {
            // position: "absolute",
            // top: "50%",
            // left: "55%",
            // transform: "translate(-50%,-50%)",
          }
        }
      >
        <div
          className="row pt-2 pb-2 mt-1 mx-auto searchwidme"
          style={{ borderRadius: "5px" }}
        >
          <div className="col-12 "></div>
          <div className="col-12  ">
            <div className="container ">
              <div className="row">
                <div className="col-1 px-0">
                  <label
                    className="form-label fonsiz mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className="col-12  col-lg-5 col-sm-5 ">
                  <div className="w-100  p-0">
                    <div className="row ">
                      <div className="col-lg-6  col-md-6  col-6 marbot marbot1 px-0 ">
                        <div class=" input-group inwid2">
                          <label
                            className="form-label fonsiz mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control selfonsiz border rounded-0"
                            style={{
                              // height: "30px",
                              width: "30px",
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text slashei px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control selfonsiz border p-0 rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-5  col-md-6  col-6 marbot  px-0 pl-0 ">
                        <div class="input-group">
                          <label
                            className="form-label fonsiz towid mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control selfonsiz rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 &&
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 slashei rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className="  border form-control selfonsiz rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2 col-12 marbot  mx-auto px-0 ">
                  <div className=" mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className="  form-control fonsiz ml-0 stru-wid rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 &&
                        typeOfWork.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2 col-12 marbot  px-0  mx-auto">
                  <div className="mx-auto w-100 text-center">
                    {/* <label
                            className="form-label input-label1 mb-0 ml-4 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                           Type of Work :&nbsp;
                          </label> */}

                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className="border form-control ml-0 fonsiz stru-wid rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option
                          key={index}
                          value={
                            item === "Structures And Layers" ? "All" : item
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center col-12 col-sm-1 col-lg-1  mx-auto">
                  <div className=""></div>

                  <button
                    disabled={successstatus ? true : false}
                    onClick={digiter == "" ? handleSubmit : ""}
                    className="btn text-center text-white btnsiz rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "12px",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
              {
                <div className="text-red er">
                  <center>{digiter}</center>
                </div>
              }             
            </div>
          </div>
        </div>
        {
          <div className="text-red er">
            <center>{digiter}</center>
          </div>
        }

{searchData.length != 0 && successstatus && (
                <div
                  className="w-100 mt-2 tabhei"
                  style={{
                    // position: "absolute",
                    // top: "61%",
                    // left: "55%",
                    // transform: "translate(-50%,-50%)",
                  }}
                >
                  <div className=" border-0 pt-0 " style={{ height: "350px" }}>
                    <table className=" table table-bordered mx-auto mt-0">
                      <thead
                        style={{
                          background:
                            "linear-gradient(to right,#4889CF,#144A9A)",
                          fontSize: "12px",
                          // position: "sticky",
                          // top: "0",
                        }}
                      >
                        <tr>
                          <th>Chainage</th>
                          {(formData.structure === "Structures" ||
                            formData.structure === "All" ||
                            formData.structure === "Structures And Layers") && (
                            <th>Structure ID</th>
                          )}
                          {(formData.structure === "Layers" ||
                            formData.structure === "All" ||
                            formData.structure === "Structures And Layers") && (
                            <th>Layer ID</th>
                          )}
                          <th>Item Of Work</th>
                          <th>Quantity</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody className="border">
                        {currentItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="p-2"
                                style={{ color: "#FF004D", fontSize: "12px" }}
                              >
                                {item.chainage}
                              </td>
                              {(formData.structure === "Structures" ||
                                formData.structure === "All" ||
                                formData.structure ===
                                  "Structures And Layers") && (
                                <td
                                  style={{ fontSize: "12px" }}
                                  className="text-dark p-2"
                                >
                                  {item.str_id}
                                </td>
                              )}
                              {(formData.structure === "Layers" ||
                                formData.structure === "All" ||
                                formData.structure ===
                                  "Structures And Layers") && (
                                <td
                                  style={{ fontSize: "12px" }}
                                  className="text-dark p-2"
                                >
                                  {item.layer_id}
                                </td>
                              )}
                              <td
                                className="p-2"
                                style={{ color: "#FF004D", fontSize: "12px" }}
                              >
                                {item.item_of_work}
                              </td>

                              <td style={{ fontSize: "12px" }} className="p-2">
                                {item.qty === 0 ? "" : item.qty + "\u00A0"}
                                {item.unit}
                              </td>

                              <td style={{ fontSize: "12px" }} className="p-2">
                                {item.bill_status}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-flex w-100 justify-content-between mt-2 marg2 mx-auto align-items-center">
                      <p className="mb-0" style={{ fontWeight: "500" }}>
                        Showing &nbsp;
                        <span style={{ fontWeight: "bold" }}>
                          {startIndex + 1}
                        </span>{" "}
                        to{" "}
                        <span style={{ fontWeight: "bold" }}>{endIndex}</span>{" "}
                        of{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {searchData?.length || 0}
                        </span>
                      </p>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<GrFormNext />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={<GrFormPrevious />}
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        forcePage={currentPage} // Reset current page to active page
                      />
                    </div>
                  </div>
                </div>
              )}

      </div>
      {searchData.length == 0 && successstatus && (
        <div
          className="w-75  "
          style={{
            position: "absolute",
            top: "50%",
            left: "57%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <center>
            <div className="login-error">
              <b>No Data Available</b>{" "}
            </div>
          </center>
        </div>
      )}
    </>
  );
}

export default Boq;
