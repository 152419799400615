import React, { useState, useEffect } from 'react';
import './Home.css';

import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaPlusCircle } from 'react-icons/fa';
import { AiOutlinePlusCircle } from 'react-icons/ai'; // Fixed the import path
import RequestModal from '../Modal/CreateRequest';
import StatusupdateModal from '../Modal/Edit';
import Renewal from '../Modal/Renewal';
import ViewTickets from '../Modal/ViewTickets';
import { baseurl } from '../BaseUrl/Baseurl';
import cancelledimg from '../../assets/images/Status/cancelled.png';
import acceptedimg from '../../assets/images/Status/accepted.png';
import pendingimg from '../../assets/images/Status/pending.png';
import axios from 'axios';
import close from '../../assets/images/Status/closed.png';
import open from '../../assets/images/Status/open.png';
import renewal from '../../assets/images/Status/renewal.png';
import renewed from '../../assets/images/Status/renewed.png';
import suspended from '../../assets/images/Status/suspended.png';
import { unhover } from '@testing-library/user-event/dist/hover';
import Billing from './Billing1';
//import Siteupdatespage from './Siteupdate1';
import Siteupdatespage from './SiteUpdateNewDesign';
import Historypage from './History';
import logout from '../../assets/images1/SignOut.png'
import ButtonLink from '../ButtonLink/ButtonLink';
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import { connect, useDispatch, useSelector } from 'react-redux'

const WeareHere = () => {
  const [btnname, setBtnname] = useState('');
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [project_renewalid, setProject_renewalid] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [successstatus, setSuccessstatus] = useState(false)
  const [logoutstatus, setLogoutstatus] = useState(false)
  const userData = useSelector((state) => state.userDetails)

  const dispatch = useDispatch()


  const [siteupdates, setsiteupdates] = useState(true);
  const [billing, setBilling] = useState(false);
  const [History, setHistory] = useState(false);

  const handlePeriodClick=()=>{
    setsiteupdates(true)
    setBilling(false)
    setHistory(false)

  }
const handleLocationClick=()=>{
  setBilling(true)
  setsiteupdates(false)
  setHistory(false)
  }

  const handleMilestoneClick=()=>{
    setsiteupdates(false)
    setBilling(false)
    setHistory(true)
    }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

//     axios({
//       method: 'GET',
//       url: baseurl + '/api/project/renewal/list',
//       headers: {
//         'Content-Type': 'application/json', 
//  Authorization: 'Bearer ' +userData.token,
//       },
//     })
//       .then((response) => {
//         const renewalData = response.data;
//         setRenewallist(renewalData);
//         setSuccessstatus(true)
//       })
//       .catch((error) => {
//         ////console.log(error);
//       });

//     axios({
//       method: 'GET',
//       url: baseurl + '/api/support/issue/ticket/list',
//       headers: {
//         'Content-Type': 'application/json', 
//         Authorization: 'Bearer ' +userData.token,
//       },
//     })
//       .then((response) => {
//         const TicketData = response.data;
//         setTicketlist(TicketData);
//        // setLoading(false)
//       })
//       .catch((error) => {
//         ////console.log(error);
//       });
  }, []);




  const toggleRenewalStatus = () => {
    setRenewalstatus(false);

    //////alert("modal false is running")
  };

  const toggleViewTicketStatus = () => {
    setViewticketstatus(false);
  };

  const requestdata = () => {
    setRequeststatus(true);
  };



  const RenewalUpdate = (id) => {
    setProject_renewalid(id);
    //////alert("Before"+renewalstatus)
    setRenewalstatus(true);
    //////alert("After"+renewalstatus)
  };

  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };

  function onExit(e)
  {
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        companyName: userData.companyName,
        clientId: userData.clientId,
        // currentprojectId:pid,
        // dropdownOption:'Project Timeline',
        // projectType:ptype,
        projectName: userData.projectName,
        projectMenuIndex: userData.projectMenuIndex,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebarReportsMenus: userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus: userData.sidebarWorkDetailsMenus,
        dropdownViewStatus: userData.dropdownViewStatus,
        chainageArr:userData.chainageArr,
        projectStatus:true,
        projectsubname:'Dashboard',
        loginUsername:userData.loginUsername,
        engineerLoginStatus:false,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );

setLogoutstatus(true)
  }

  return (
    <div className=''>
    <div className="mar-bill container-fluid engdivwid"style={{position:'absolute',top:'20%',left:'55%', transform:'translate(-50%,-50%)'}}>
  
     {logoutstatus &&<ButtonLink btnPath="/Dashboard" />} 
       
       <div className="pt-2">
         <h5
           className="text-center "
           style={{
             fontFamily: "plus jakarta sans",
             color: "#BB1245",
             fontWeight: "600",
           }}
         >
           <div className=" mx-auto  text-center rounded w-100  " style={{backgroundColor:'', color:'#1953AA'}}>
            <h5 className=" engfonsiz1  mb-0 ">{userData.projectName}</h5>
            <h6
      className="text-center engfonsiz1 mt-1"
      style={{  fontWeight: "600" }} 
    >
      Total Chainage : 1/0 - 6/0
    </h6>
          </div>
         </h5>
        
                 {/* <div className="text-right">
                 <Link onClick={(e) => onExit(e)} >

<img src={logout} className='icon1st-new ' />
</Link>
                 </div> */}
              

         <div className="row  mx-auto  engmenuwid">
       



              <div className="col-4 text-center  ">
                <button onClick={handlePeriodClick}
                  className="btn  mx-auto sub1"
                  style={ { backgroundColor:siteupdates ? '#2F619C' : '#ffffff',color: siteupdates ? '#ffffff' : '#2F619C',fontSize:'14px' } }
                >
                  Site Updates
                </button>
              </div>

              <div className="col-4 text-center  ">
                <button onClick={handleLocationClick}
                  className="btn   mx-auto sub1 "
                  style={ { backgroundColor: billing ? '#2F619C' : '#ffffff',color:billing ? '#ffffff' : '#2F619C', fontSize:'14px' } }
                >
                Billing
                </button>
              </div>

              <div className="col-4 text-center ">
                <button onClick={handleMilestoneClick}
                  className="btn   mx-auto sub1"
                  style={ { backgroundColor: History ? '#2F619C' : '#ffffff',color:History ? '#ffffff' : '#2F619C',fontSize:'14px' } }
                >
                  History
                </button>
              </div>
            </div>
        

          

           
         </div>
         
         {billing&&<Billing/>}
         {siteupdates&&<Siteupdatespage/>}
         {History&&<Historypage/>}
       
</div> </div>
     
   
  );
};

export default WeareHere;
