import React from "react";
import RadialBar from "../ApexCharts/RadialBar1";
import "./AfterLoginHome.css";
import "./Home.css";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaStarAndCrescent } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import RequestModal from "../Modal/CreateRequest";
import StatusupdateModal from "../Modal/Edit";
import Renewal from "../Modal/Renewal";
import MaterialRquest from "../Modal/MaterialRequest";
import ViewTickets from "../Modal/ViewTickets";
import { baseurl } from "../BaseUrl/Baseurl";
import ButtonLink from "../ButtonLink/ButtonLink";
import search1 from "../../assets/brickdemoicons/finder png 1.png";
import completed from "../../assets/images/completed-5.png";
import inprogress from "../../assets/images/buttons/inprogress.png";
import received from "../../assets/images/buttons/received.png";
import delayed from "../../assets/images/buttons/delayed.png";
import cancelled from "../../assets/images/buttons/cancelled.png";
import sandcorrect from "../../assets/images/buttons/sandcorrect.png";
import brickcorrect from "../../assets/images/buttons/brickcorrect.png";
import stonescorrect from "../../assets/images/buttons/stonescorrect.png";
import ironrodcorrect from "../../assets/images/buttons/ironrodcorrect.png";
import cementcorrect from "../../assets/images/buttons/cementcorrect.png";
import ordered from "../../assets/images/buttons/ordered.png";
import sandhead from "../../assets/images/resources/sandhead.png";
import brick from "../../assets/images/resources/brick.png";
import cement from "../../assets/images/resources/cement.png";
import ironrod from "../../assets/images/resources/ironrod.png";
import sand from "../../assets/images/resources/sand.png";
import stones from "../../assets/images/resources/stones.png";
import tiles from "../../assets/images/resources/tiles.png";
import LineChart from "../ApexCharts/LineChart";
import LineChart1 from "../ApexCharts/LineChart1";
import MultiBarGraph from "../ApexCharts/Multibarchart";
import LineChart2 from "../ApexCharts/LineChart2";
import cancelledimg from "../../assets/images/Status/cancelled.png";
import renewal from "../../assets/images/Status/renewal.png";
import renewed from "../../assets/images/Status/renewed.png";
import suspended from "../../assets/images/Status/suspended.png";
import $ from "jquery";
import axios from "axios";
import DocumentView from "../Modal/DocumentView";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import iconimg from "../../assets/images1/Projectstatus.png";
import banner from "../../assets/images1/backgroundabstractwhite.png";
import search from "../../assets/images1/Vector.png";
import icon1 from "../../assets/images1/Vector1.png";
import icon2 from "../../assets/images1/Stack.png";
import icon3 from "../../assets/images1/CalendarCheck.png";
import icon4 from "../../assets/images1/ShirtFolded.png";
import icon5 from "../../assets/images1/Money.png";
import icon6 from "../../assets/images1/SoccerBall.png";
import icon7 from "../../assets/images1/TidalLogo.png";
import icon8 from "../../assets/images1/Vector2.png";
import icon9 from "../../assets/images1/ReplitLogo.png";
import icon10 from "../../assets/images1/View.png";
import icon11 from "../../assets/images1/btnicon.png";
import icon12 from "../../assets/images1/map.png";
import icon13 from "../../assets/images1/Vector2.png";
import icon14 from "../../assets/images1/dots.png";
import icon15 from "../../assets/images1/iconx.png";
import icon16 from "../../assets/images1/CalendarX.png";
import icon17 from "../../assets/images1/pngwing.png";
import ConfirmationModel from  '../Modal/ProjectListConfirmation'
const WeareHere = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState("");
  const [project_renewalid, setProject_renewalid] = useState("");
  const [radialData, setRadialData] = useState("");
  const [ticketno, setTicketno] = useState("");
  const [list, setList] = useState([]);
  const [navstatus, setNavstatus] = useState(false);
  const [list1, setList1] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [yaxis, setYaxis] = useState([]);
  const [zaxis, setZaxis] = useState([]);
  const [itemOfWorkover, setItemOfWorkover] = useState([]);
  const [itemCompletedover, setItemCompletedover] = useState([]);
  const [itemPendingover, setItemPendingover] = useState([]);
  const userData = useSelector((state) => state.userDetails);
  const [levellist, setLevelist] = useState("");
  const [data2, setData2] = useState([]);
  const drop = React.useRef(null);
  const [materialRequestId, setMaterialRequestId] = useState("");
  const [materialRequestprojectid, setMaterialRequestprojectid] = useState("");
  const [materialRequeststatus, setMaterialRequeststatus] = useState(false);
  const [materialRequestedstatus, setMaterialRequestedstatus] = useState(false);
  ///////////////drop down list ///////////////////////////////////////
  const [imagelist, setImagelist] = useState("");
  // const [modalStatus, setModalStatus] = useState(false);
  const [purchaseorder, setPurchaseorder] = useState("");
  const [purchaseorderurldownload, setPurchaseorderurldownload] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);
  const [area, setArea] = useState("");

  const [arealist, setArealist] = useState([]);
  const [circle, setCircle] = useState("");
  const [division, setDivision] = useState("");
  const [subdivision, setSubdivision] = useState("");
  const [projectlist, setProjectlist] = useState([]);
  const [circlelist, setCirclelist] = useState([]);
  const [divisionlist, setDivisionlist] = useState([]);
  const [subdivisionlist, setSubdivisionlist] = useState([]);
  const [dropdownlist, setDropdownlist] = useState([]);
  const [reportslist, setReportslist] = useState([]);
  const [workdetailslist, setWorkdetailslist] = useState([]);
  const [projname, setProjname] = useState("");
  const [search, setSearch] = useState("");
  const [projectstatus, setProjectstatus] = useState("");
  const [filteredSearch, setFilteredSearch] = useState([]);

  const [modalstatus, setModalstatus] = useState(userData.projectStatus);
  const isDivisionPresent = userData.searchParams.includes("Division");
  const isSubDivisionPresent = userData.searchParams.includes("Sub-Division");
  const isCirclePresent = userData.searchParams.includes("Circle");
  const len=userData.searchParams.length

  const dispatch = useDispatch();
  useEffect(
    () => {
      // console.log
      // ("HI",userData)

      // axios({
      //   method: "GET",

      //   url: baseurl + "/api/project/list/get?client_id=" + userData.clientId,

      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: "Bearer " + userData.token,
      //   },
      // })
      //   .then((response) => {
      //     ////console.log(response.data);
      //     const RequestData = response.data;
      //     // setRequestdata1(RequestData)
      //     ////console.log("requesttttt", RequestData);

      //     setList(RequestData);
      //     setSuccessstatus1(true);
      //   })
      //   .catch((error) => {
      //     ////console.log(error);
      //     // setErrormsg(error.response.data.message)
      //   });

      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      //Ticket list
      // axios({
      //   method: "GET",

      //   url: baseurl + "/api/support/issue/ticket/list",

      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: "Bearer " + userData.token,
      //   },
      // })
      //   .then((response) => {
      //     ////console.log(response.data);
      //     const TicketData = response.data;
      //     setTicketlist(TicketData);
      //   })
      //   .catch((error) => {
      //     ////console.log(error);
      //     // setErrormsg(error.response.data.message)
      //   });
    },
    [
      // modelStatus, updatestatus, levellist
    ]
  );

  useEffect(() => {
    if(isCirclePresent)
    {
      
    axios({
      method: "GET",

      url: baseurl + "/api/project/list/circle",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const circle = data.circle_list || [];
        setCirclelist(circle);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
    //Division
    if(isDivisionPresent)
    {
      const encodedcircle = encodeURIComponent(userData.circle);
      
    axios({
      method: "GET",

      url: baseurl + "/api/project/list/division?circle="+encodedcircle,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const division = data.division_list || [];
        setDivisionlist(division);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }

    //Sub Division
    if(isSubDivisionPresent)
    {
      const encodeddivision = encodeURIComponent(userData.division);
    axios({
      method: "GET",

      url: baseurl + "/api/project/list/sub_division?division="+encodeddivision,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const subdivision = data.sub_division_list || [];
        setSubdivisionlist(subdivision);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }

    //Sub Division
    // axios({
    //   method: "GET",

    //   url: baseurl + "/api/project/list/area",

    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer " + userData.token,
    //   },
    // })
    //   .then((response) => {
    //     const data = response.data;
    //     const area = data.area_list || [];
    //     setArealist(area);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });
  }, []);

  function handleCircle(e) {
    setCircle(e.target.value);
    setDivision([])
    setSubdivision([])
    const encodedcircle = encodeURIComponent(e.target.value);


    axios({
      method: "GET",

      url: baseurl + "/api/project/list/division?cicle="+encodedcircle,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const data = response.data;
        const division = data.division_list || [];
        setDivisionlist(division);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  function handleDivision(e) {
    setDivision(e.target.value);
setSubdivision([])
const encodeddivision = encodeURIComponent(e.target.value);
      axios({
        method: "GET",
  
        url: baseurl + "/api/project/list/sub_division?division="+encodeddivision,
  
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
      })
        .then((response) => {
          const data = response.data;
          const subdivision = data.sub_division_list || [];
          setSubdivisionlist(subdivision);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

  }
  function handleSubDivision(e) {
    setSubdivision(e.target.value);
  }
  function handleArea(e) {
    setArea(e.target.value);
  }

  function handleSearch(e) {
    const value = e.target.value;
    setSearch(value);

    if (value === "") {
        setFilteredSearch(projectlist); 
        return;
    }

    const filterBySearch = projectlist.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
    );
    
    setFilteredSearch(filterBySearch);
}

  function onSearch(event) {
    event.preventDefault();

    // Encode the query parameter
    const encodedcircle = encodeURIComponent(circle);
    const encodeddivision = encodeURIComponent(division);
    const encodedsubdivision = encodeURIComponent(subdivision);


    // Construct the full URL
    const fullUrl = `${baseurl}/api/project/list?is_name_only_rqd=1&circle=${encodedcircle}&division=${encodeddivision}&subdivision=${encodedsubdivision}`;
    axios({
      method: "GET",

      url: fullUrl,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const dt = response.data || [];
        const list = dt.project_name_list;
        //console.log(list);
        setProjectlist(list);
        
      })
      .catch((error) => {});
  }

  // const samplelist = [{ "level": "F0'", "status": "Completed", "cdate": "20-01-2024", "pdate": "30-01-2024", "Pstatus": "Paid", "pamount": "200000" },
  // { "level": "F0", "status": "Completed", "cdate": "19-02-2024", "pdate": "28-02-2024", "Pstatus": "Not Paid", "pamount": "NA" },]

  function getProjectDetails(pname) {

    setProjname(pname);
  
    axios({
      method: "GET",

      url:
        baseurl +
        "/api/project/get/menu/list?project_name=" +
       pname+
        //'PR-2'+
        "&role=" +
        userData.role,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        //console.log(response.data);
      console.log("Sub Menus"+JSON.stringify(response.data));

        const listofmenus = response.data;

        setWorkdetailslist(listofmenus.Work_Details);
        setReportslist(listofmenus.Reports);
        setDropdownlist(listofmenus.Projects);
        onReduxupdate(
          pname,
          listofmenus.Work_Details,
          listofmenus.Reports,
          listofmenus.Projects
        );
      })
      .catch((error) => {
        ////console.log(error);
        // setErrormsg(error.response.data.message)
      });
  }
  function onReduxupdate(pname, workdetails, reports, projects) {
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: "Projects",
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        companyName: userData.companyName,
        clientId: userData.clientId,
        // currentprojectId:pid,
        // dropdownOption:'Project Timeline',
        // projectType:ptype,
        projectName: pname,
        projectMenuIndex: 0,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: projects,
        sidebarReportsMenus: reports,
        sidebarWorkDetailsMenus: workdetails,
        dropdownViewStatus: true,
        projectStatus:true,
        projectsubname:'Dashboard',
        loginUsername:userData.loginUsername,
        engineerLoginStatus:false,
        searchParams:userData.searchParams,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );
    setNavstatus(true);
  }

  const modelclose = () => {
   
    setModalstatus(false)
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: "Projects",
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        companyName: userData.companyName,
        clientId: userData.clientId,
        // currentprojectId:pid,
        // dropdownOption:'Project Timeline',
        // projectType:ptype,
        projectName: '',
        projectMenuIndex: 0,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: [],
        sidebarReportsMenus: [],
        sidebarWorkDetailsMenus: [],
        dropdownViewStatus: false,
        projectStatus:false,
        projectsubname:"Dashboard",
        loginUsername:userData.loginUsername,
        engineerLoginStatus:false,
        searchParams:userData.searchParams,
        statename:userData.statename,
        circle:userData.circle,
        division:userData.division,
        subdivision:userData.subdivision
      })
    );

  };

  const backtoNormal = () => {
  
    setNavstatus(true);
  };

useEffect(()=>
{

  if(!projectstatus)
  {
  if(userData.role==="AE"|| userData.role==="ADE" && projectlist.length==0)
  {
    const encodedcircle = encodeURIComponent(userData.circle);
    const encodeddivision = encodeURIComponent(userData.division);
    const encodedsubdivision = encodeURIComponent(userData.subdivision);

 
    // Construct the full URL
    const fullUrl = `${baseurl}/api/project/list?is_name_only_rqd=1&circle=${encodedcircle}&division=${encodeddivision}&subdivision=${encodedsubdivision}`;
    axios({
      method: "GET",

      url: fullUrl,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const dt = response.data || [];
        const list = dt.project_name_list;
        setProjectstatus(true)
        ////console.log(list);
        setProjectlist(list);
        
      })
      .catch((error) => {});
    }
  }
  
},[])
  return (
    <div
      // className={userData.sidebartoggleStatus ? "cont-margin-sidebar " : "cont-margin1"}
      className=" cont-margin1 "
       style={{overflowY: 'hidden'}}
    >
      {navstatus &&
        userData.projectName != undefined &&
        userData.projectName != "" &&
        userData.projectName != null && <ButtonLink btnPath={"/" + userData.projectsubname} />}

{ userData.projectName != undefined &&
        userData.projectName != "" &&
        userData.projectName != null &&
                <ConfirmationModel status={userData.projectStatus} handleClick={backtoNormal} onExit={modelclose}/>}


    { (userData.projectName == undefined ||
        userData.projectName ==="" ||
        userData.projectName === null ) && <div className="margindiv" style={{marginTop:'85px'}}>
        {/* <div class="pt-2">
          <center><h5><b>Tamilnadu National Highway</b></h5> </center>
        </div>
        <div class="text-right ">
          <Link to="#" className='linkstcolorforprojectlist'>Pending for Approval &gt;</Link>
          <br>
          </br>
          <Link to="#" className='linkstcolorforprojectlist'>Work Completion Certificate &gt;</Link>
        </div> */}

        <div class="row pt-1">
          <div class=" container col-lg-10 col-xl-10">
            <div class="row">
              {list.length == 0 && successstatus1 && (
                <div class="pt-1 login-error col-xl-12 col-lg-12 col-md-12 ">
                  <center>
                    <h6>No Projects</h6>
                  </center>
                </div>
              )}

              <div class="col-xl-4 col-lg-4 ">
                <div class="title1  d-inline-flex justify-content-around align-items-center  ">
                  <p className="mb-1  text-white" style={{ fontWeight: "500" }}>
                    <img className="imgsizpro mr-1" src={iconimg} width={30} alt="" />
                    <span className="prosiz">Projects</span>
                  </p>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6  "></div>
              <div class="col-xl-2 col-lg-2  "></div>

              {/* <div class="col-xl-2 col-lg-2 pt-4  ">
            <Link to="#" className='linkstcolorforprojectlist'><img src={search} alt='' />&nbsp;Advance Search</Link>
          </div> */}

             <div class="col-xl-12 col-lg-12  conthei mb-2 ">
             {userData.role!="AE" && userData.role!="ADE" &&
                <div className="projectdetailsdiv ">
                  <div className="paddivallside ">
                    <div class="row pt-3">
                    {isCirclePresent &&  <div class={len==3 ?"col-lg-4 col-md-4 col-xl-4 padbox1":len==2 ?"col-lg-6 col-xl-6" :"col-lg-12 col-xl-12" }>
                        <label for="formFileLg" className="dropdown-text mb-0 mt-2 ">
                          Circle
                        </label>
                        <select
                          className="dropdownbox-box pl-2 "
                          style={{ fontSize: "13px" }}
                          id="circle"
                          name="circle"
                          defaultValue={circle}
                          value={circle}
                          onChange={handleCircle}
                        >
                          <option value="" selected>
                            Select
                          </option>

                          {circlelist.length != 0 &&
                            circlelist.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>}
                    
                    {isDivisionPresent &&  <div class={len==3 ?"col-lg-4 col-md-4 col-xl-4 padbox1":len==2 ?"col-lg-6 col-xl-6" :"col-lg-12 col-xl-12" }>
                        <label for="formFileLg" className="dropdown-text mb-0 mt-2 ">
                          Division
                        </label>
                        <select
                          className="dropdownbox-box pl-2 "
                          style={{ fontSize: "13px" }}
                          id="division"
                          name="division"
                          defaultValue={division}
                          value={division}
                          onChange={handleDivision}
                        >
                          <option value="" selected>
                            Select
                          </option>

                          {divisionlist.length != 0 &&
                            divisionlist.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>}

                     {isSubDivisionPresent && 
                     <div class={len==3 ?"col-lg-4 col-md-4 col-xl-4 padbox1":len==2 ?"col-lg-6 col-xl-6" :"col-lg-12 col-xl-12" }>
                        <label for="formFileLg" className="dropdown-text mb-0 mt-2 ">
                          Sub-Division
                        </label>
                        <select
                          className="dropdownbox-box pl-2 "
                          style={{ fontSize: "13px" }}
                          id="subdivision"
                          name="subdivision"
                          defaultValue={subdivision}
                          value={subdivision}
                          onChange={handleSubDivision}
                        >
                          <option value="" selected>
                            Select
                          </option>

                          {subdivisionlist.length != 0 &&
                            subdivisionlist.map((item, index) => {
                              return (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </div>
}
                    {(isCirclePresent || isDivisionPresent || isSubDivisionPresent) && <div class="col-xl-12 col-lg-12 padbox ">
                        <center>
                          <button className="searchbtndash " onClick={onSearch}>
                            Search
                          </button>
                        </center>
                    
                      </div>}
                    </div>
                  </div>
                </div>}
              </div>

            </div>
            <div class="col-lg-2 col-xl-2"></div>

            {projectlist.length != 0 && (
              <div>
                <div class="col-xl-4 col-md-4 col-sm-4 col-8 col-lg-4  pl-0   pt-0 ">
                  <div className="input-group radius in  mb-2 ">
                    <input value={search} onChange={handleSearch}
                      className="form-control border-0 bg-transparent text-black  siztex"
                     
                      placeholder="Keyword search"
                      type="search"
                    />
                    <div class="input-group-append  ">
                      <button
                        class="input-group-text radi border-0  "
                        id="basic-addon1"
                      >
                        <img className="icosi" src={search1} />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12  p-0 ">
                  <div className="projectdetailsdiv   ">
                    <div className="paddivallside bg-white scrollable-div   roadtypename" style={{height: userData.role!="AE" && userData.role!="ADE"  ? '' : '350px'}}>
                      <ul className="pb-0 mb-0 " style={{}}>
                        {/* <li>   GRAND SOUTHERN TRUNK ROAD (SHU-88)</li> */}
                        {/*  */}
                          {search != '' ?
                          filteredSearch.map((item,index)=>{
                            return (
                              <li className="" key={index}>
                                <Link
                                  className={
                                    projname === item
                                      ? "projectlistcolor1"
                                      : "projectlistcolor"
                                  }
                                  id="projectname"
                                  onClick={() => getProjectDetails(item)}
                                >
                                  {item}
                                </Link>
                              </li>
                            );
                          })
                          :
                            projectlist.map((item,index)=>{
                              return (
                                <li key={index}>
                                  <Link
                                    className={
                                      projname === item
                                        ? "projectlistcolor1"
                                        : "projectlistcolor"
                                    }
                                    id="projectname"
                                    onClick={() => getProjectDetails(item)}
                                  >
                                    {item}
                                  </Link>
                                </li>
                              );
                            })                          
                        }
                            
                          
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>



      }
    </div>
  );
};

export default WeareHere;
