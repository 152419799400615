import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
//import './PieChart.css';

const PieChart = ({ chartval }) => {
  const [chartState] = useState({
    series: chartval,
    colors: ["#14B842", "#FACC15", "#FA4C15"],
    options: {
      chart: {
        width: 400,
        type: "pie",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            // Custom HTML data labels
            offset: -15,
            style: {
              fontSize: "14px",
            },
            formatter: function (val, opts) {
              return `<div style="width: 100px; text-align: center;">${val}%</div>`;
            },
          },
        },
      },
      title: {
        text: "Work Delay Status",
        align: "center",
        style: {
          fontSize: "16px",
        },
      },
      legend: {
        show: false, // Disable the legend
      },
      labels: ["Completed", "Pending", "Delayed"],
      colors: ["#14B842", "#FACC15", "#FA4C15"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              offsetX: 50, // Adjust for smaller screens if needed
            },
          },
        },
      ],
    },
  });

  return (
    <div className="mt-5 mb-5 w-100 chart-container position-relative">
      <div
        id="chart"
        className="d-flex justify-content-center align-items-center w-100 "
      >
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="pie"
          width={400}
          className=""
        />
        <div
          className="position-absolute heiad"
          style={{ top: "0", right: "55px", fontSize: "14px" }}
        >
          <div className="d-flex align-items-center  w-100">
            <div
              className="rounded recsic mr-2"
              style={{
                width: "30px",
                height: "15px",
                backgroundColor: "#14B842",
              }}
            ></div>
            <span className="logsiz">Completed</span>
          </div>
          <div className="d-flex align-items-center  w-100">
            <div
              className="rounded recsic mr-2"
              style={{
                width: "30px",
                height: "15px",
                backgroundColor: "#FACC15",
              }}
            ></div>
            <span className="logsiz">Pending</span>
          </div>
          <div className="d-flex align-items-center  w-100">
            <div
              className="rounded recsic mr-2"
              style={{
                width: "30px",
                height: "15px",
                backgroundColor: "#FA4C15",
              }}
            ></div>
            <span className="logsiz">Delayed</span>
          </div>
        </div>
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PieChart;
