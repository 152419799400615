import React, { useState, useEffect } from "react";
import { RxSlash } from "react-icons/rx";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import "./EngineerHome.css";
import testing from "../../assets/brickdemoicons/testing.png";
import gallery from "../../assets/brickdemoicons/Gallerybg.png";
import cad from "../../assets/brickdemoicons/Auto cad.png";
import CustomToastContainer from "../../CustomToastContainer";
import { Modal } from "react-bootstrap";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import billhead from "../../assets/brickdemoicons/bill-head.png";
import bill from "../../assets/brickdemoicons/bill.png";
import search1 from "../../assets/brickdemoicons/finder png 1.png";
import { FcGallery } from "react-icons/fc";
import { FaFilePdf } from "react-icons/fa";
import auto from "../../assets/brickdemoicons/Auto cad.png";
import { FaXmark } from "react-icons/fa6";
import filter from  "../../assets/brickdemoicons/filter.png";
import gal from   "../../assets/brickdemoicons/gimg.png";
import './Siteupdatestyle.css'
import road from   "../../assets/images/road2.png";
import { FiEdit } from "react-icons/fi";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import ReactPaginate from "react-paginate";
import below from "../../assets/brickdemoicons/belowChainage.png";
import pdficon from "../../assets/brickdemoicons/pdf-color-red-icon.png";
import galicon from "../../assets/brickdemoicons/gal.png";
function Billing() {
  const [tableShow, setTableShow] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [billeddata, setBilleddata] = useState([]);
  const [billdisplay, setBilldisplay] = useState(false);
  const [err, setErr] = useState("");

  const [status, setStatus] = useState("");
  const [comments, setComments] = useState("");

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };
  const [cadImg, setCadImg] = useState("https://bricklane.s3.ap-south-1.amazonaws.com/6297e8d8-6689-479c-9b1a-f17beb79ba2f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T041222Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241015%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=27e8835db07df27fe51e35eb1f10e07e3a456f2b29cb76b7a16fbc9ae8ab74aa")
  const [searchData, setSearchData] = useState(([
    {
      item_of_work: "Soil Filling",
      tchainage: "1/000 - 6/000",
      str_id: "STR-002",
      layer_id: "-",
      test: "NA",
      cad: "https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
      date: "08-09-2014",
    
      update:"AE",
      cchainage:  "1/0 - 2/0",
    },
    {
      item_of_work: "Paver Slab",
      str_id: "-",
      layer_id: "LAYER-04",
      tchainage: "1/000 - 6/000",    
      test: "NA",
      cad: "https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
      date: "08-09-2014",   
      update:"AE",
      cchainage:  "1/0 - 2/0",
     
    },
    {
        item_of_work: "Earthwork",
        str_id: "STR-002",
        layer_id: "-",
        tchainage: "1/000 - 6/000",    
        test: "NA",
        cad:"https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
        date: "08-09-2014",   
        update:"AE",
        cchainage:  "1/0 - 2/0",
      },
   
    {
      item_of_work: "Stone Dust",
      str_id: "STR-003",
      layer_id: "-",
      tchainage: "1/000 - 6/000",    
        test: "NA",
        cad: "https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
        date: "08-09-2014",   
        update:"AE",
        cchainage:  "1/0 - 2/0",
    },
    {
      item_of_work: "GSB",
      str_id: "STR-004",
      layer_id: "-",
      tchainage: "1/000 - 6/000",    
      test: "NA",
      cad: "https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
      date: "08-09-2014",   
      update:"AE",
      cchainage:  "1/0 - 2/0",
     
    },
    {
      item_of_work: "PCC",
      tchainage: "1/000 - 6/000",
      str_id: "-",
      layer_id: "LAYER-02",
      test: "NA",
      cad: "https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
      date: "08-09-2014",
    
      update:"AE",
      cchainage:  "1/0 - 2/0",
    },
    {
        item_of_work: "Earthwork",
        str_id: "STR-002",
        layer_id: "-",
        tchainage: "1/000 - 6/000",    
        test: "NA",
        cad:"https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
        date: "08-09-2014",   
        update:"AE",
        cchainage:  "1/0 - 2/0",
      },
   
    {
      item_of_work: "GSB V",
      str_id: "STR-003",
      layer_id: "-",
      tchainage: "1/000 - 6/000",    
        test: "NA",
        cad: "https://bricklane.s3.ap-south-1.amazonaws.com/509f6212-8f4a-40c8-98e5-385757312449.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241109T072948Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241109%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=91758b594a1da692f582ec1f6536c0a360d802550b6d488c20495b382a4fabab",
        date: "08-09-2014",   
        update:"AE",
        cchainage:  "1/0 - 2/0",
    },
  
  ]
  ));
  const [cadShow, setCadShow] = useState();
  const userData = useSelector((state) => state.userDetails);

  const handleCadOpen = (img) => {
    setCadImg(img);
    setCadShow(true);
  };

  const handleCadClose = () => {
    setCadShow(false);
    setCadImg("");
  };
  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [editshow, setEditshow] = useState(false);

  const handleEditClick = (
   
  ) => {
  
    setEditshow(true);
   
  };
  
  const testDep = [
    {
      test_name: "Testing Dependency",
      Doc_name: "Document.pdf",
      sub_date: "9 Nov 2024",
    },
    {
      test_name: "Testing Dependency",
      Doc_name: "Document.pdf",
      sub_date: "9 Nov 2024",
    },
  ];
  const Gallery = [
    {
      img_name: "img1.jpeg",
      sub_date: "9 Nov 2024",
    },
    {
      img_name: "img2.jpeg",
      sub_date: "9 Nov 2024",
    },
  ];

  const Data = [
    {
      item_of_work: "Earth Work Excavation",
      stru_id: "TYPE2-KERB PCC LHS (OUTER)",
      layer_id: "TYPE2-EARTHWORK-FP",
    },
  ];
  useEffect(() => {
    // Reset offset when currentPage or itemsPerPage changes
    const newOffset = currentPage * itemsPerPage;
    setItemOffset(newOffset);
  }, [currentPage, itemsPerPage]);
  
  useEffect(() => {
    // Reset to first page when historylist changes
    setCurrentPage(0);
  }, [searchData]);
  
  const startIndex = itemOffset + 1;
  const endIndex = Math.min(itemOffset + itemsPerPage, searchData.length);
  const currentPageData = searchData.slice(itemOffset, itemOffset + itemsPerPage);
  const pageCount = Math.ceil(searchData.length / itemsPerPage);
  
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
 
  const [filteredChainage, setFilteredChainage] = useState([]);
  const [successstatus, setSuccessstatus] = useState(false);
  const [galshow, setGalShow] = useState(false);

  const[uploadedGalleryImage,setUploadedGalleryImage]=useState("https://bricklane.s3.ap-south-1.amazonaws.com/6297e8d8-6689-479c-9b1a-f17beb79ba2f.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20241015T041222Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA6HJAEZZE4OYEKVUY%2F20241015%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=27e8835db07df27fe51e35eb1f10e07e3a456f2b29cb76b7a16fbc9ae8ab74aa")

  function showGalleryImage()
  {
 setGalShow(true)
  }
  const handleEditClose = () => {
    setEditshow(false);
  }

  
  const handleGalClose = () => {
    setGalShow(false);
  }

//   const onApproval = (e) => {
//     e.preventDefault();
//     const data = {
//       approval_status: 2,
//       updation_data: tableids,
//     };
//     const apiUrl =
//       `${baseurl}/api/site_updates/update/approval_status?project_id=` +
//       userData.projectId;

//     axios
//       .put(apiUrl, data, {
//         headers: {
//           "Content-Type": "application/json",
//           // 'Authorization': 'Bearer' + ' ' + userData.engineertoken
//         },
//       })

//       .then((response) => {
//         ////////console.log(response.data)
//         const data = response.data;
//         //////alert("success")
//         ////console.log(JSON.stringify(tableids))

//         setTableids([]);
//         setIsAllChecked(false);
//         setSelectedSearch("");
//         setUpdateitemofworkstatus(true)
//         refreshItemofworklist();
//       })
//       .catch((error) => {
//         ////////console.log(error)
//         // setErrormsg(error.response.data.message)
//       });


//   };

//   const onRejected = (e) => {
//     e.preventDefault();

//     const data = {
//       approval_status: 0,
//       updation_data: tableids,
//     };

//     const apiUrl =
//       `${baseurl}/api/site_updates/update/approval_status?project_id=` +
//       userData.projectId;

//     axios
//       .put(apiUrl, data, {
//         headers: {
//           "Content-Type": "application/json",
//           // 'Authorization': 'Bearer' + ' ' + userData.engineertoken
//         },
//       })

//       .then((response) => {
//         ////////console.log(response.data)
//         const data = response.data;
       
//         setTableids([]);
//         setIsAllChecked(false);
     
//        setUpdateitemofworkstatus(true)
//         setSelectedSearch("");
//         refreshItemofworklist();
//       })
//       .catch((error) => {
//         ////////console.log(error)
//         // setErrormsg(error.response.data.message)
//       });

   
//     
//   };



  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);
  const [digiter, setDigiter] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [billlist, setBilllist] = useState([]);
  const [viewbilldata, setViewbilldata] = useState([]);

  const [billliststatus, setBillliststatus] = useState(false);

  const [viewbilldata1, setViewbilldata1] = useState([]);
  // Handle individual item selection

  // Handle "Select All" or "Deselect All"
  const handleSelectAllChange = () => {
    if (selectedItems.length === searchData.length) {
      // If all items are selected, deselect all
      setSelectedItems([]);
    } else {
      // If not all items are selected, select all
      const allItems = searchData.map((item) => ({
        tID: item.table_id,
        qty: item.qty,
        item_of_work: item.item_of_work,
      }));
      setSelectedItems(allItems);
    }

    //////console.log("All select"+JSON.stringify(selectedItems))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);

    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false)
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));
      
      setStructure([]);
      setSuccessstatus(false)
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setSuccessstatus(false)
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }

      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "" && formData.to!="") {
    
      if (e.target.name==="to1") {
          axios
            .get(`${baseurl}/api/work_details/get/type_of_work`, {
              params: {
                project_id: userData.projectId,
                start_chainage: `${formData.from}.${formData.from1}`,
                end_chainage: `${formData.to}.${e.target.value}`,
              },
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userData.token}`,
              },
            })
            .then((response) => {
              const data = response.data;
              const type_of_work_list = data.type_of_work_list;
              setTypeOfWork(type_of_work_list);
              // ////////alert("type of work", type_of_work_list);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          //////console.log(strs_or_layers_list);
          // ////////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    setTableShow(false)

  };

  const validate = () => {

    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios
        .get(
          `${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=billing&`,
          {
            params: {
              project_id: userData.projectId,
              strs_or_layers: formData.structure,
              type_of_work: formData.type,
              start_chainage: `${formData.from}.${formData.from1}`,
              end_chainage: `${formData.to}.${formData.to1}`,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((response) => {
          const data = response.data.str_or_layer_ids_data;
          //////console.log(data);
          ////////alert(JSON.stringify(data))
          setSuccessstatus(true)
          //setSearchData(data);
         
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      setTableShow(true);
      setErr("");
    }
  };

  const handleSubmit = (e) => {
    setMessageList([]);

    e.preventDefault();
   // validate();
  };
  const handleItemChange = (item_of_work, qty, tid) => {
    setSelectedItems((prevSelectedItems) => {
      const found = prevSelectedItems.find((obj) => obj.tID === tid);
      if (!found) {
        //////console.log("Adding item:", { tID: tid, qty, item_of_work });
        return [...prevSelectedItems, { tID: tid, qty, item_of_work }];
      } else {
        //////console.log("Removing item:", { tID: tid });
        return prevSelectedItems.filter((item) => item.tID !== tid);
      }
    });
  };

  
  const isCheckedchecking = (item) => {
    //////console.log(item.table_id)
    const checked = selectedItems.some(
      (selectedItem) => selectedItem.tID === item.table_id
    );
    //////console.log(`Is item ${item.tID} checked?`, checked);
    return checked;
  };

  useEffect(() => {
    //////console.log('Updated selectedItems:', selectedItems);
  }, [selectedItems]);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleImgClose = () => {
    setShowImg(false);
    setSelectedItems([]);
    setBilleddata([]);

    axios
      .get(
        `${baseurl}/api/work_details/get/str_or_layer_ids?rqd_for=billing&`,
        {
          params: {
            project_id: userData.projectId,
            strs_or_layers: formData.structure,
            type_of_work: formData.type,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.str_or_layer_ids_data;
        //////console.log(data);
        ////////alert(JSON.stringify(data))
       // setSearchData(data);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handlebilllistclose = () => {
    setBillliststatus(false);
    setBilldisplay(false);
  };
 
  return (
    <>
      <CustomToastContainer messageList={messageList} />

      { searchData.length==0 && successstatus &&
        <div
          className="w-75"
          style={{
            position: "absolute",
            top: "230%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >     
         <center><div className="login-error"><b>No Data Available</b> </div></center> 
          </div>}

          
      {searchData.length!=0  &&(
        <div>
                   
          <div
            className="w-100  ml-4"
            style={{
              position: "absolute",
              top: "350%",
              left: "43%",
              transform: "translate(-50%,-50%)",
            }}
          >
            {" "}
            {/* <div className="marg">
              <div className="input-group  radius  text-end in mt-4  mb-1 ">
                <input
                  style={{ fontSize: "15px" }}
                 // value={searchTerm}
                //  onChange={handleSearch}
                  className="form-control p-0  pl-3 border-0 bg-transparent text-black "
                  placeholder="Keyword search"
                  type="search"
                />{" "}
                <div class="input-group-append  ">
                  <button
                    onClick={""}
                    class="input-group-text radi border-0  "
                    id="basic-addon1"
                  >
                    <img src={search1} width={16} />
                  </button>
                </div>
              </div>
            </div> */}
            {/* <div className="w-100 d-flex justify-content-start  pb-2 ml-5 pt-2">
              <button
                // disabled={selectedItems.length==0}
                // style={{backgroundColor: selectedItems.length==0? "":"#bbbbbb"

                // }}
               // onClick={(e) => viewBill()}
                className="mr-2 btn boxsha btn-sm text-white"
                      style={{
                        backgroundColor: "#069E55",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
              >
                Approved
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                // disabled={selectedItems.length==0}
                // style={{backgroundColor: selectedItems.length==0? "":"#bbbbbb"

                // }}
              //  onClick={(e) => viewBill()}
                className="mr-2 btn btn-sm  text-danger border border-danger"
                style={{ fontSize: "12px", fontWeight: "600" }}
              >
                Rejected
              </button>
            </div>{" "} */}
<div class="row">
    <div class="col-lg-2 col-xl-2"  style={{ fontWeight: "500",fontSize:'13px' }}>
    <div 
    //className="siteupdatefilter"
    style={{
//   backgroundSize: "cover !important",
//   backgroundRepeat: "no-repeat",
     backgroundColor:"#7E253A",
   borderRadius:'20px !important',
  height:'40px'
}}
class="row"


>
 <div className='d-inline-flex p-2 pb-3  justify-content-around align-items-center  '>
     <img src={filter}/>
      <p className=" text-white mb-0 " style={{ fontWeight: "500",fontSize:'18px' }}>
                    
                    &nbsp;Filter
                  </p></div>
                  
               
<div class="col-lg-12" >


                  <select
                           // onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control border rounded-0"
                            style={{
                              // height: "30px",
                              width: "100%",
                              fontSize:'15px'
                              
                            }}
                          >
                            <option value="" >Completed</option>
                            <option value="" >Pending</option>
                          </select>           
                  </div>
                  <div class="pt-2 pb-2">Type</div>
                  <div class="col-lg-12">
                          <input
                //className="ui-checkbox"
                // checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
              />  &nbsp;&nbsp;<font className="typefont">Select All</font>
              </div>
              <div class="col-lg-12">
               <input
               //  className="ui-checkbox"
                // checked={selectedItems.length === searchData.length}
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                onChange={handleSelectAllChange}
                type="checkbox"
              />  &nbsp;&nbsp;<font className="typefont">Type 1</font>
              </div>
              <div class="col-lg-12">
               <input
               // className="ui-checkbox"
               // checked={selectedItems.length === searchData.length}
               style={{
                width: "13px",
                height: "13px",
                verticalAlign: "middle",
                marginTop: "2px" // Adjust as needed for further fine-tuning
              }}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp;<font className="typefont">Type 2  </font>            </div>
                <div class="col-lg-12">
               <input
                 style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
               // className="ui-checkbox"
                //checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp;<font className="typefont">Type 3</font>
              </div>
              <div class="col-lg-12">
               <input
                 style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                //className="ui-checkbox"
                //checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp;<font className="typefont">Type 4</font>
              </div>
              {/* <div class="col-lg-12">
               <input
                 style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                //className="ui-checkbox"
                //checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp;Type 5
              </div>
              <div class="col-lg-12">
               <input
               // className="ui-checkbox"
                //checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "-2px" // Adjust as needed for further fine-tuning
                  }}
              /> &nbsp;&nbsp;Type 6
              </div> */}
              <div class="col-lg-6">
             
              </div>
              <div class="col-lg-6">
              <font className="typefont">Clear all</font> 
              </div>
              <hr style={{color:"black"}} width="100%"></hr>
{/*  */}
<div class="pt-2 pb-2">Chainage</div>
                  <div class="col-lg-12">
                          <input
                //className="ui-checkbox"
                // checked={selectedItems.length === searchData.length}
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                onChange={handleSelectAllChange}
                type="checkbox"
              />  &nbsp;&nbsp;  <font className="typefont">Select All</font> 
              </div>
              <div class="col-lg-12">
               <input
                // className="ui-checkbox"
                // checked={selectedItems.length === searchData.length}
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp; <font className="typefont">1/0 - 1/3</font> 
              </div>
              <div class="col-lg-12">
               <input
                //className="ui-checkbox"
               // checked={selectedItems.length === searchData.length}
               style={{
                width: "13px",
                height: "13px",
                verticalAlign: "middle",
                marginTop: "2px" // Adjust as needed for further fine-tuning
              }}
                onChange={handleSelectAllChange}
                type="checkbox"
              />  &nbsp;&nbsp; <font className="typefont">1/5 - 2/3</font> </div>
                <div class="col-lg-12">
               <input
                //className="ui-checkbox"
                //checked={selectedItems.length === searchData.length}
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp;<font className="typefont">2/5 - 2/09</font> 
              </div>
              {/* <div class="col-lg-12">
               <input
               // className="ui-checkbox"
                //checked={selectedItems.length === searchData.length}
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "-2px" // Adjust as needed for further fine-tuning
                  }}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp;5/0 - 5/90
              </div> */}
            
              <div class="col-lg-6 ">
              
              </div>
              <div class="col-lg-6 ">
              <font className="typefont">Clear all</font> 
              </div>
              <hr style={{color:"black"}} width="100%"></hr>
{/*  */}

<div class="pt-2 pb-2">Structure / Layer</div>
                  <div class="col-lg-12">
                          <input
                //className="ui-checkbox"
                // checked={selectedItems.length === searchData.length}
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
                onChange={handleSelectAllChange}
                type="checkbox"
              />  &nbsp;&nbsp;  <font className="typefont">Select All</font> 
              </div>
              <div class="col-lg-12">
               <input
                // className="ui-checkbox"
                style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "-2px" // Adjust as needed for further fine-tuning
                  }}
                // checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              />  &nbsp;&nbsp;<font className="typefont">Structures and Layers</font>
              </div>
              <div class="col-lg-12">
               <input
                  style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "2px" // Adjust as needed for further fine-tuning
                  }}
               // checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              />  &nbsp;&nbsp;  <font className="typefont">Structures</font>  </div>
                <div class="col-lg-12">
               <input
                 style={{
                    width: "13px",
                    height: "13px",
                    verticalAlign: "middle",
                    marginTop: "-2px" // Adjust as needed for further fine-tuning
                  }}
                //checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              /> &nbsp;&nbsp; <font className="typefont">Layers </font> 
              </div>
              <div class="col-lg-6">
             
              </div>
              <div class="col-lg-6">
              <font className="typefont">Clear all</font> 
              </div>
              <hr style={{color:"black"}} width="100%"></hr>
{/*  */}
<div class="col-lg-12">
<center>
<button
            
                    // onClick={digiter == "" ? handleSubmit : ""}
                    className="btn text-center text-white rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    Search
                  </button>

                  </center>
                  </div>
                  </div>

    </div>
        <div class="col-lg-10 col-xl-10 ">
            <div
              className="  border-0 table-bordered pt-0  pl-0"
              style={{ height: "340px",fontSize:"12px" ,fontWeight:"400"}}
            >
                <div  className="siteupdate   justify-content-around align-items-center"   style={{ height: "40px"}}>
<center><div class="pt-2"></div>Site Updates</center>
                </div>
                {currentPageData.map((item, index) => {
                    return (
                
<div className="shadow-box1 mb-3">
<div class="row ">
<div class="col-lg-3">
<input
                //className="ui-checkbox"
                // checked={selectedItems.length === searchData.length}
                onChange={handleSelectAllChange}
                type="checkbox"
              
                style={{
                   width: "13px",
                   height: "13px",
                   verticalAlign: "middle",
                   marginTop: "-2px" // Adjust as needed for further fine-tuning
                 }}
              /> &nbsp; &nbsp; Item of Work&nbsp;:&nbsp;{item.item_of_work}
</div>
<div class="col-lg-3">
Total Chainage&nbsp;:&nbsp;{item.tchainage}
</div>
<div class="col-lg-5">

</div>
<div class="col-lg-1">
<button
                                    className="btn p-0 pl-2"
                                    onClick={() =>
                                      handleEditClick(
                                       
                                      )
                                    }
                                  >
                                    <FiEdit style={{ color: "#00A350" }} />
                                  </button>
</div>
<div class="col-lg-3">
Structure ID&nbsp;:&nbsp;{item.str_id}
</div>
<div class="col-lg-3">
    Testing Dependency &nbsp;:&nbsp;{item.test}
</div>
<div class="col-lg-2">
Cad Image &nbsp;:&nbsp;  <button
                                  className="btn p-0"
                                  onClick={() =>
                                    handleCadOpen(item.cad)
                                  }
                                >
                                  <img src={auto} alt="CAD" width={24} />
                                </button>
</div>
<div class="col-lg-4 ">
Contractor Submission Date &nbsp;:{item.date}
</div>
<div class="col-lg-3">
Layer ID&nbsp;:&nbsp;{item.layer_id}
</div>
<div class="col-lg-3">
Updated By &nbsp;:&nbsp;{item.update}
</div>
<div class="col-lg-2">
Gallery Image &nbsp;:&nbsp;  <button
                                  className="btn p-0"
                                  onClick={() =>
                                    showGalleryImage()
                                  }
                                >
                                  <img src={gal} alt="CAD" width={24} />
                                </button>
</div>
<div class="col-lg-4">
Completed Chaainage &nbsp;:&nbsp;{item.cchainage}
</div>

</div>
</div>
                    )}

                )}
                <div>

                </div>
                <div className="d-flex w-100 justify-content-between marg2 mx-auto  align-items-center">
  {/* <p className="mb-0" style={{ fontWeight: "500" }}>
    Showing &nbsp;
    <span style={{ fontWeight: "bold" }}>{startIndex}</span> to{" "}
    <span style={{ fontWeight: "bold" }}>{endIndex}</span> of {searchData.length}
  </p> */}
  <ReactPaginate
        breakLabel="..."
        nextLabel={<GrFormNext />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<GrFormPrevious />}
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage} // Reset current page to active page
      />
</div>
</div>



</div>



              
   <Modal centered show={galshow} onHide={handleGalClose}>
          <Modal.Body className="bg-white p-2 rounded">
            <div
              className="d-flex justify-content-end align-items-center "
              style={{ marginTop: "-2%", marginRight: "-2%" }}
            >
              <button className="btn  " onClick={handleGalClose}>
                <FaXmark />
              </button>
            </div>
                  <img
                   
                   src={road}
                   
                   width="100%"
                   height={300}
                 />      
                 </Modal.Body>
        </Modal>

        <Modal centered show={cadShow} onHide={handleCadClose}>
          <Modal.Body className="bg-white p-2 rounded">
            <div
              className="d-flex justify-content-end align-items-center "
              style={{ marginTop: "-2%", marginRight: "-2%" }}
            >
              <button className="btn  " onClick={handleCadClose}>
                <FaXmark />
              </button>
            </div>
                  <img
                   
                   src={cadImg}
                   
                   width="100%"
                   height={300}
                 />      
                 </Modal.Body>
        </Modal>



        <Modal centered show={editshow} onHide={handleEditClose} >
          <div className="d-flex justify-content-end align-items-center  ">
            <button
              style={{
                backgroundColor: "#E56C6C",
                position: "absolute",
                zIndex: "999",
              }}
              className="btn ml-3 rounded-circle"
              onClick={handleEditClose}
            >
              <FaXmark className="text-white"/>
            </button>
          </div>
          <Modal.Body className="bg-white p-2 rounded custom-modal-body ">
            <form className="mb-2 pt-2">
              <center>
                <h5 className="p-3 pt-2" style={{background:`url(${below})`,backgroundRepeat:'no-repeat',backgroundPosition:'bottom center',backgroundSize:'250px '}}>Chainage:1/000-6/000</h5>
              </center>
              <div class="shadow-box pt-0  mb-3">
                <div class="row">
                  <div class="col-lg-12 bluestyle"></div>
                  <div class="col-lg-12">
                    <table
                      style={{ color: "black", borderCollapse: "collapse" }}
                    >
                      {Data.map((item, index) => {
                        return (
                          <>
                            <tbody key={index}>
                              <tr className="text-start">
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Item of work
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  :
                                </td>
                                <td className=""
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {item.item_of_work}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Structure Id
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {item.stru_id}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Layer Id
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                    color: "black",
                                  }}
                                >
                                  {item.layer_id}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "2px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  Cad Image
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    padding: "4px",
                                    lineHeight: "1",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  <img src={cad} height={20} />
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
              <div class="shadow-box pt-0">
                <div className=" border-bottom mb-2 p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={testing}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      Testing Dependency
                    </span>
                  </center>
                </div>
                {testDep.map((item, index) => {
                  return (
                    <>
                      <div className="" key={index}>
                        <div style={{fontSize:'14px'}}>Test Name : {item.test_name}</div>
                        <div className="row border mt-2 p-2 container mx-auto rounded">
                          <div className="col-6 px-0 d-flex align-items-center ">
                            <img
                              src={pdficon}
                              width={40}
                              height={40}
                              className="img-fluid mr-2"
                            />
                            <div className="" style={{ fontSize: "14px" }}>
                              <div>{item.Doc_name}</div>
                              <div
                                style={{ color: "#838080", fontSize: "12px" }}
                              >
                                {item.sub_date}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn mr-3 text-white"
                              style={{
                                fontSize: "14px",
                                backgroundColor: "#054993",
                              }}
                            >
                              Approve
                            </button>
                            <button
                              className="btn "
                              style={{
                                fontSize: "14px",
                                color: "#FF0000",
                                border: "1px solid #FF0000",
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div class="shadow-box mt-2 pt-0">
                <div className=" border-bottom mb-2 p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={gallery}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      Gallery Image
                    </span>
                  </center>
                </div>
                {Gallery.map((item, index) => {
                  return (
                    <>
                      <div className="" key={index}>
                        <div className="row border mt-2 p-2 container mx-auto rounded">
                          <div className="col-6 px-0 d-flex align-items-center ">
                            <img
                              src={galicon}
                              width={40}
                              height={40}
                              className="img-fluid mr-2"
                            />
                            <div className="" style={{ fontSize: "14px" }}>
                              <div>{item.img_name}</div>
                              <div
                                style={{ color: "#838080", fontSize: "12px" }}
                              >
                                {item.sub_date}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <button
                              className="btn mr-3 text-white"
                              style={{
                                fontSize: "14px",
                                backgroundColor: "#054993",
                              }}
                            >
                              Approve
                            </button>
                            <button
                              className="btn "
                              style={{
                                fontSize: "14px",
                                color: "#FF0000",
                                border: "1px solid #FF0000",
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              <div class="shadow-box mt-2 pt-0">
                <div className=" border-bottom mb-2 p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={testing}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      AE Section
                    </span>
                  </center>
                </div>
                <div className="">
                  <div className="row border mt-2 p-2 container mx-auto rounded">
                    <div className="col-4 px-0 ">
                      <label style={{fontSize:'14px'}} className="form-label">Status</label>
                      <select
                        onChange={handleStatusChange}
                        value={status}
                        className="form-control"
                        style={{ fontSize: "14px" }}
                      >
                        <option>Accept</option>
                        <option>To Be Viewed</option>
                        <option>Rework</option>
                      </select>
                    </div>
                    <div className="col-8">
                      <label style={{fontSize:'14px'}} className="form-label">Comments</label>
                      <textarea
                        onChange={handleCommentsChange}
                        value={comments}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="shadow-box pt-0 mt-2  mb-3">
                <div className=" border-bottom mb-2  p-2">
                  <center className="d-flex align-items-center justify-content-center">
                    <img
                      src={testing}
                      width="50"
                      height="25"
                      className="img-fluid"
                    />
                    <span className="mt-1" style={{ fontWeight: "bold" }}>
                      To Be Viewed
                    </span>
                  </center>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <table
                      style={{ color: "black", borderCollapse: "collapse" }}
                    >
                      <tr>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          Approved Chainage
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          From &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            1/000
                          </span>
                          &nbsp;&nbsp; to &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            2/054
                          </span>
                        </td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          Rework Chainage
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            padding: "4px",
                            lineHeight: "1",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          From &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            4/361
                          </span>
                          &nbsp;&nbsp; to &nbsp;&nbsp;
                          <span
                            className="p-1 rounded"
                            style={{
                              backgroundColor: "#D8F5FF",
                              color: "#054993",
                            }}
                          >
                            4/552
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className="btn p-1"
                  style={{ color: "#265DF5", border: "1px solid #265DF5" }}
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>






            

</div>

          </div>
        </div>
      )}
    </>
  );
}

export default Billing;
