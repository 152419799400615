import React from 'react'
import { Link } from 'react-router-dom'
import { Component, useState , useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import Chart from 'react-apexcharts'
const RadialBar = ({
radialcolor,
radialwidth,
SeriesValue,
labelValue
}) => {
  
 
    const [state1, setState1] = useState({
      options: {
        colors:[radialcolor],
        plotOptions: {
          
          radialBar: {
            // startAngle: -180,
            // endAngle: 180,

            track: {
              background: "#f2f2f2",
              strokeWidth: "100%"
            },
            dataLabels: {
              show: true,
              value: {
                show: false,
                fontSize: "22px",
                color: radialcolor,
                padding:10
                
              },
              total: {
                show: true,
                label: labelValue ,
                color: '#ffffff',
                fontSize: "14px",
                padding:10
              }
            }
          }
        }
      },

      series: [SeriesValue]
    }
    )


    return (
      <div className="donut">
        <Chart
          options={state1.options}
          series={state1.series}
          type="radialBar"
          width={radialwidth}
          // height="100%"
        />
      </div>
    );
  
}

export default RadialBar;
