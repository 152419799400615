import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './Completedwork.css';
import { GrFormPrevious, GrFormNext } from 'react-icons/gr';
import { PiNotepad } from 'react-icons/pi';
import search1 from '../../assets/brickdemoicons/finder png 1.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from '../BaseUrl/Baseurl';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoginStatus } from '../../store/userDetails';

const ContractorProjectList = () => {
  const itemsPerPage = 5;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userDetails);

  useEffect(() => {
    axios({
      method: "GET",
      url: baseurl + "/api/project/list",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
      params: {
        sub_division: userData.subdivision,
        is_name_only_rqd: 1,
        for_contractor_or_ae: 1,
      },
    })
      .then((response) => {
        const completed = response.data.project_details_list;
        setData(completed);
        setFilteredData(completed);
        ////console.log(JSON.stringify(completed));       
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(filteredData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredData.length / itemsPerPage));
  }, [itemOffset, filteredData]);




  const onReduxupdate = (arr, projectId,projectName,startChainage,endChainage) => {
    dispatch(
      updateLoginStatus({
        ...userData,
        chainageArr: arr,
        projectStatus: true,
        projectId: projectId,
        projectName: projectName,
        startChainage:startChainage,
        endChainage:endChainage
      })
    );
  };





  useEffect(() => {
    const sortedData = [...filteredData].sort((a, b) => {
      const aValue =
        typeof a[sortField] === "string"
          ? a[sortField].toLowerCase()
          : a[sortField];
      const bValue =
        typeof b[sortField] === "string"
          ? b[sortField].toLowerCase()
          : b[sortField];

      return aValue < bValue
        ? sortDirection === "asc"
          ? -1
          : 1
        : aValue > bValue
        ? sortDirection === "asc"
          ? 1
          : -1
        : 0;
    });

    setFilteredData(sortedData);
    setItemOffset(0);
  }, [sortField, sortDirection]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData.length;
    setItemOffset(newOffset);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.filter((item) =>
      item.project_name.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
    setItemOffset(0);
  };

  const handleSort = (field) => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);
  };

  const viewClick = (project) => {
    const start = project.start_chainage.split("/")[0];
    const end = project.end_chainage.split("/")[0];
    const projectId = project.project_id;
    const projectName = project.project_name;
    const generatedArray = generateArray(parseInt(start), parseInt(end));
    const startChainage = project.start_chainage
    const endChainage = project.end_chainage
    onReduxupdate(generatedArray, projectId, projectName,startChainage,endChainage);
    navigate("/Siteupdates");

  };

  function downloadXl(projid,projname)
  {

   axios({
    method: "GET",
    url: baseurl + "/api/bill_report/export_as_excel?project_id="+projid,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + userData.token,
    },
    responseType: "blob", // This is important for handling binary data
  })
    .then((response) => {
      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
      // Create a link element, set the download attribute with a filename, and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download =  `${projname}.xlsx`; // Set the file name for download
      link.click(); // Programmatically click the link to trigger the download
  
     
    })
    .catch((error) => {
      console.error(error); 
    });
  
  }

  

  const generateArray = (start, end) => {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  const startIndex = itemOffset + 1;
  const endIndex = Math.min(itemOffset + itemsPerPage, filteredData.length);

  return (
    <div className="mt-2 mb-4 mb-md-0 container w-100 px-1">
      <div
        className="p-2 rounded text-white mx-auto"
        style={{ backgroundColor: "#414AB0" }}
      >
        <h6 className="d-flex align-items-center mr-2 mb-0">
          <PiNotepad style={{ fontSize: "30px" }} />
          Project List
        </h6>
      </div>

      <div className="col-xl-4 col-lg-4 col-10 pl-0 pt-3">
        <div className="input-group radius w-75 mb-3">
          <input
            onChange={handleSearch}
            value={searchTerm}
            className="form-control border-0 bg-transparent text-black"
            placeholder="Keyword search"
            type="search"
          />
          <div className="input-group-append">
            <button
              className="input-group-text radi border-0"
              id="basic-addon1"
            >
              <img src={search1} alt="Search Icon" />
            </button>
          </div>
        </div>
      </div>

      <table className="table table-borderless">
        <thead className='' style={{ background: "#DBDADA" }}>
          <tr>
            <th style={{fontSize:'14px',width:'200px'}} className=" border rounded-0 p-1  text-dark text-center">
              S.no 
            </th>
            <th style={{fontSize:'14px'}} className="p-1 text-dark text-center" onClick={() => handleSort('project_name')}>
              Project Name
              {sortField === 'project_name' ? (sortDirection === 'asc' ? '↑' : '↓') : '↓'}
            </th>
            <th style={{fontSize:'14px'}} className=" border rounded-0 p-1 text-dark text-center">Details</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr className="border" key={item.project_id}>
              <td>{itemOffset + index + 1}</td>
              <td>{item.project_name}</td>
              <td className='d-flex flex-column flex-md-row justify-content-center'>
                <button
                onClick={()=>viewClick(item)}
                 
                  className="btn btn-sm mr-md-3 text-white"
                  style={{ backgroundColor: "#069E55", fontSize: "12px" , width:'100px' }}
                >
                  View
                </button>
                <button
                  className="btn px-0 btn-sm text-white"
                  style={{ backgroundColor: "#A44F00", fontSize: "12px",width:'100px' }}
                  onClick={()=>downloadXl(item.project_id,item.project_name)}
                >
                  Download XL
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex align-items-center">
        <p style={{ fontWeight: "500",fontSize:'14px' }} className='mb-0'>
          Showing <span style={{ fontWeight: "bold" }}>{startIndex}</span> to
          <span style={{ fontWeight: "bold" }}>{endIndex}</span> of
          {filteredData.length}
        </p>

        <ReactPaginate
          breakLabel="..."
          nextLabel={<GrFormNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel={<GrFormPrevious />}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default ContractorProjectList;
