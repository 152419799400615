import React from "react";

function TestingDependency() {
  return (
    <div
      className="container w-75  "
      style={{
        // position: "absolute",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%,-50%)", 
        marginTop:'10%'       
      }}
    >
      <p className="text-center" style={{fontWeight:"bold",color:"#D10C47"}}>As  Per  Employer  Requirement  Testing Dependency Added  Between
      Structures  And  Layers.</p>
    </div>
  );
}

export default TestingDependency;
