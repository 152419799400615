import React, { useEffect, useState } from "react";
import { RxSlash } from "react-icons/rx";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomToastContainer from "../../CustomToastContainer";
import gimg from "../../assets/brickdemoicons/gimg.png";
import galleryImg1 from "../../assets/brickdemoicons/cadex.jpg";
import { Modal } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import autocad from "../../assets/brickdemoicons/cad.png";
import ReactPaginate from "react-paginate";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
function History() {
  const [tableShow, setTableShow] = useState(false);
  const [typeOfWork, setTypeOfWork] = useState([]);
  const [structure, setStructure] = useState([]);
  const [err, setErr] = useState("");
  const userData = useSelector((state) => state.userDetails);
  // const [chainage] = useState([]);
  const [filteredChainage, setFilteredChainage] = useState([]);
  const [showImg, setShowImg] = useState(false);

  const [cadImg, setCadImg] = useState([]);
  const [galleryImg, setGalleryImg] = useState("");
  const [showImg1, setShowImg1] = useState(false);
  const [historylist, setHistorylist] = useState([]);
 
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  
  useEffect(() => {
    // Reset offset when currentPage or itemsPerPage changes
    const newOffset = currentPage * itemsPerPage;
    setItemOffset(newOffset);
  }, [currentPage, itemsPerPage]);
  
  useEffect(() => {
    // Reset to first page when historylist changes
    setCurrentPage(0);
  }, [historylist]);
  
  const startIndex = itemOffset + 1;
  const endIndex = Math.min(itemOffset + itemsPerPage, historylist.length);
  const currentPageData = historylist.slice(itemOffset, itemOffset + itemsPerPage);
  const pageCount = Math.ceil(historylist.length / itemsPerPage);
  
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
 



  
  const [successstatus, setSuccessstatus] = useState(false);
  const [formData, setFormData] = useState({
    from: "",
    from1: "",
    to: "",
    to1: "",
    type: "",
    structure: "",
  });
  const [messageList, setMessageList] = useState([]);
  const [digiter, setDigiter] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;

    setMessageList([]);

    if (name === "from") {
      setFormData({
        from: value,
        from1: "",
        to: "",
        to1: "",
        type: "",
        structure: "",
      });
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "from1") {
      setFormData((prev) => ({
        ...prev,
        from1: value,
        to: "",
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "to") {
      setFormData((prev) => ({
        ...prev,
        to: value,
        to1: "",
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "to1") {
      setFormData((prev) => ({
        ...prev,
        to1: value,
        type: "",
        structure: "",
      }));
      setTypeOfWork([]);
      setStructure([]);
      setSuccessstatus(false);
    } else if (name === "type") {
      setFormData((prev) => ({
        ...prev,
        type: value,
        structure: "",
      }));

      setStructure([]);
      setSuccessstatus(false);
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setSuccessstatus(false);
    }

    const digit = e.target.value.replace(/\D/g, "");
    if (e.target.name === "from1" || e.target.name === "to1") {
      if (value.length != 3 && name === "from1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in from field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      if (value.length !== 3 && name === "to1") {
        const digit_check =
          "Chainage must be contain 3 digit after / in to field";
        setMessageList((prevMessages) => [...prevMessages, digit_check]);
      }
      setFormData((prevData) => {
        const updatedData = { ...prevData, [name]: digit };

        if (parseFloat(updatedData.to) <= parseFloat(updatedData.from)) {
          if (parseFloat(updatedData.from1) >= parseFloat(updatedData.to1)) {
            const gt_check = "Enter values greater than start chainage";
            setMessageList((prevMessages) => [...prevMessages, gt_check]);
          } else {
            setErr("");
          }
        }

        return updatedData;
      });
    }

    if (e.target.name === "from") {
      const selectedValue = parseInt(e.target.value, 10);
      const filtered = userData.chainageArr.filter(
        (value) => value >= selectedValue
      );
      setFilteredChainage(filtered);
    }

    //type of work , http://localhost:8080/brick/api/work_details/get/type_of_work?project_id=PR-2&start_chainage=2.03&end_chainage=5.7
    if (formData.from != "" && formData.from1 != "" && formData.to != "") {
      if (e.target.name === "to1") {
        axios
          .get(`${baseurl}/api/work_details/get/type_of_work`, {
            params: {
              project_id: userData.projectId,
              start_chainage: `${formData.from}.${formData.from1}`,
              end_chainage: `${formData.to}.${e.target.value}`,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          })
          .then((response) => {
            const data = response.data;
            const type_of_work_list = data.type_of_work_list;
            setTypeOfWork(type_of_work_list);
            // alert("type of work", JSON.stringify(type_of_work_list));
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }

    //structure //http://localhost:8080/brick/api/work_details/get/strs_or_layers?project_id=PR-2&type_of_work=TYPE1
    if (e.target.name === "type" && e.target.value != "") {
      axios
        .get(`${baseurl}/api/work_details/get/strs_or_layers`, {
          params: {
            project_id: userData.projectId,
            type_of_work: e.target.value,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((response) => {
          const data = response.data;
          const strs_or_layers_list = data.strs_or_layers_list;
          setStructure(strs_or_layers_list);
          //console.log(strs_or_layers_list);
          // ////////alert(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const validate = () => {
    if (
      formData.from === "" ||
      formData.from1 === "" ||
      formData.to === "" ||
      formData.to1 === "" ||
      formData.type === "" ||
      formData.structure === ""
    ) {
      const empty_check = "Please fill all the fields";
      setMessageList((prevMessages) => [...prevMessages, empty_check]);
    } else {
      axios({
        method: "GET",

        url:
          baseurl +
          "/api/work_details/get/str_or_layer_ids?project_id=" +
          userData.projectId +
          "&type_of_work=" +
          formData.type +
          "&strs_or_layers=" +
          formData.structure +
          "&rqd_for=history" +
          "&start_chainage=" +
          `${formData.from}.${formData.from1}` +
          "&end_chainage=" +
          `${formData.to}.${formData.to1}`,

        headers: {
          "Content-Type": "multipart/form-data",
          //  Authorization: 'Bearer ' + userData.token
        },
      })
        .then((response) => {
          //console.log(response.data);
          const RequestData = response.data;
          const list = RequestData.str_or_layer_ids_data;
          setHistorylist(list);
          setSuccessstatus(true);
           console.log(JSON.stringify(historylist));
        })
        .catch((error) => {
          //console.log(error);
          // setErrormsg(error.response.data.message)
        });
    }
  };

  const handleSubmit = (e) => {
    setMessageList([]);

    e.preventDefault();
    validate();
  };

  const [isChecked, setisChecked] = useState("");
  const [input2, setInput2] = useState("");

  // Handle change for multiple inputs
  const handleInputChange = () => {
    setisChecked(!isChecked);
  };
  const handleInput1Change = (event) => {
    setInput2(event.target.checked);
    //  ////////alert(event.target.value);
  };

  const handleImgShow = (img) => {
    setGalleryImg(img);
    setShowImg(true);
  };

  const handleImgShow1 = (img) => {
    setCadImg(img);
    setShowImg1(true);
  };

  const handleImgClose = () => {
    setShowImg(false);
    setGalleryImg("");
  };

  const handleImgClose1 = () => {
    setShowImg1(false);
    setCadImg([]);
  };

  return (
    <>
      <CustomToastContainer messageList={messageList} />

      <div
        className="w-100 method-cont1 marsearch "
        style={{
          position: "absolute",
          top: "130%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div
          className="row  mx-auto searchwidme  py-2 "
          style={{ borderRadius:'5px' }}
         
        >
          <div className="col-12 "></div>
          <div className="col-12 col-sm-12 ">
            <div className="container-fluid ">
              <div className="row">
                <div className="">
                  <label
                    className="form-label  fonsizeng mb-0 mt-1"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Chainage&nbsp;
                  </label>
                </div>
                <div className="col-12 col-lg-5 col-sm-5 ">
                  <div className="w-100  p-0">
                    <div className="row ">
                    <div className="col-lg-6 col-sm-6 col-6 px-0 marbot"style={{zIndex:'999'}}>
                        <div class=" input-group inwid2">
                          <label
                            className="form-label fonsizeng mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            From :&nbsp;
                          </label>
                          <select
                            onChange={handleChange}
                            value={formData.from}
                            name="from"
                            className="form-control selfonsiz border rounded-0"
                            style={{
                            
                              width: "28px",
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {userData.chainageArr != undefined &&
                              userData.chainageArr.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>

                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text slashei px-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="from1"
                            type="text"
                            value={formData.from1}
                            onChange={handleChange}
                            className="form-control selfonsiz border p-0 rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-5 col-sm-6 col-6 px-0 pl-0 marbot">
                        <div class="input-group ">
                          <label
                            className="form-label  fonsizeng towid mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            &nbsp;&nbsp;To :&nbsp;
                          </label>

                          <select
                            name="to"
                            onChange={handleChange}
                            value={formData.to}
                            className="border form-control selfonsiz rounded-0"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <option value=""></option>
                            {formData.from1.length == 3 &&
                              filteredChainage.map((item, index) => {
                                return (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                          </select>
                          <div class="input-group-append rounded-0">
                            <span
                              class="input-group-text px-0 slashei rounded-0"
                              id="basic-addon1"
                            >
                              <RxSlash />
                            </span>
                          </div>
                          <input
                            maxLength={3}
                            name="to1"
                            type="text"
                            value={formData.to1}
                            onChange={handleChange}
                            className=" border form-control selfonsiz rounded-0 pl-2"
                            style={{
                              fontSize: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2 col-12 px-0 px-sm-1 px-md-2  marbot">
                  <div className=" mx-auto w-100 text-center">
                   

                    <select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                      className=" border form-control fonsiz   rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="">Type of Work...</option>
                      {formData.to1.length == 3 &&
                        typeOfWork.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-2 col-md-3 col-12 px-0 px-sm-1 px-md-2  px-lg-4 marbot">
                  <div className="mx-auto w-100 text-center">
                   
                    <select
                      name="structure"
                      onChange={handleChange}
                      value={formData.structure}
                      className=" border form-control fonsiz   rounded-0"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <option value="" disabled>Structure/Layer</option>
                      {structure.map((item, index) => (
                        <option
                          key={index}
                          value={
                            item === "Structures And Layers" ? "All" : item
                          }
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center col-lg-1 col-sm-1 col-md-1 col-12">
                  <div className="col-1 col-sm-1"></div>

                  <button
                    disabled={successstatus ? true : false}

                    onClick={digiter == "" ? handleSubmit : ""}
                    className="btn text-center text-white btnsiz rounded-pill mt-0 "
                    style={{
                      backgroundColor: "#1953AA",
                      fontSize: "14px",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
       
        {historylist.length == 0 && successstatus && (
          <div
            className="w-75 "
            style={{
              position: "absolute",
              top: "230%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <center>
              <div className="login-error">
                <b>No Data Available</b>{" "}
              </div>
            </center>
          </div>
        )}
      
      {historylist.length !== 0 && successstatus && (
  <div
    className="pt-0  "
    style={{
      position: "absolute",
      top: "260px",
      left: "48%",
      transform: "translate(-50%,-50%)",
      width: "113%",
      height: "400px",
    }}
  >
    <table className="table table-bordered m-0 rounded-bottom">
      <thead
        style={{
          background: "linear-gradient(to right,#4889CF,#144A9A)",
          color: "#ffffff",
          fontSize: "13px",
        }}
      >
        <tr>
        {formData.structure!='Layers'&&  <th className="p-0 py-2" >Structures</th>}
        {formData.structure!='Structures'&&   <th className="p-0 py-2" >Layers</th>}
          {/* <th className="p-0 py-2 px-2" >Type Of Work</th> */}
          <th className="p-0 py-2" style={{ width: '12%' }}>Testing Dependency</th>
          <th className="p-0 py-2">Bill Quantity</th>
          <th className="p-0 py-2">Bill Amount</th>
          <th className="p-0 py-2">Current Status</th>
          <th className="p-0 py-2">Balance Quantity</th>
          <th className="p-0 py-2">Balance Amount</th>
          <th className="p-0 py-2">No. of Bills</th>
          <th className="p-0 py-2">Cad Image</th>
          <th className="p-0 py-2">Gallery Image</th>
        </tr>
      </thead>
      <tbody>
        {currentPageData.map((item, index) => (
          <tr key={index}>
         {formData.structure!='Layers'&&    <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.str_id}</td>}
         {formData.structure!='Structures'&&   <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.layer_id}</td>}
            {/* <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.type_of_work}</td> */}
            <td className="p-0 py-2 px-1" style={{ fontSize: "11px" }}>{item.test_name}</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.billed_volume_or_area_qty}</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.billed_amount}</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.current_status}%</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.balance_volume_or_area_qty}</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.balance_amount}</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>{item.no_of_running_bills}</td>
            <td className="p-0 py-2" style={{ fontSize: "11px" }}>
              {item.cad_image_url.length !== 0 ? (
                <img
                style={{cursor:'pointer'}}
                  src={autocad}
                  alt="img"
                  width={16}
                  onClick={() => handleImgShow1(item.cad_image_url)}
                />
              ) : (
                'N/A'
              )}
            </td>
            <td className="p-0 py-2" style={{ fontSize: "11px", }}>
              {item.progress_image_url.length !== 0 ? (
                <img
                style={{cursor:'pointer'}}
                  src={gimg}
                  alt="img"
                  width={25}
                  onClick={() => handleImgShow(item.progress_image_url)}
                />
              ) : (
                'N/A'
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="d-flex w-100 justify-content-between mt-2 marg2 mx-auto align-items-center">
  <p className="mb-0" style={{ fontWeight: "500" }}>
    Showing &nbsp;
    <span style={{ fontWeight: "bold" }}>{startIndex}</span> to{" "}
    <span style={{ fontWeight: "bold" }}>{endIndex}</span> of {historylist.length}
  </p>
  <ReactPaginate
        breakLabel="..."
        nextLabel={<GrFormNext />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<GrFormPrevious />}
        renderOnZeroPageCount={null}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={currentPage} // Reset current page to active page
      />
</div>
  </div>
)}


        {showImg && (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            animation={true}
          >
            <Modal.Body>
              <div className=" bg-white ">
                <div className="w-100 d-inline-flex justify-content-end pr-2">
                  <button className="btn p-0 bg-white" onClick={handleImgClose}>
                    <IoMdClose />
                  </button>
                </div>
                <div className="text-center">
                  {/* {galleryImg.map((imgSrc, index) => (
                            <img
                              key={index}
                              src={imgSrc}
                              alt={`cadimg-${index}`}
                              width={768}
                              height={500}
                            />
                          ))} */}
                  <img src={galleryImg} width={768} height={500} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {showImg1 && (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            animation={true}
          >
            <Modal.Body>
              <div className=" bg-white ">
                <div className="w-100 d-inline-flex justify-content-end pr-2">
                  <button
                    className="btn p-0 bg-white"
                    onClick={handleImgClose1}
                  >
                    <IoMdClose />
                  </button>
                </div>
                <div className="text-center">
                  {cadImg.map((imgSrc, index) => (
                    <img
                      key={index}
                      src={imgSrc}
                      alt={`cadimg-${index}`}
                      width={768}
                      height={500}
                    />
                  ))}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
}

export default History;
