import React from 'react'
import { Link } from 'react-router-dom'
import { Component, useState , useEffect} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
// import './RoadProjectDashboard.css';
import Chart from 'react-apexcharts'
const RadialBar = ({
radialcolor,
radialwidth,
SeriesValue,
labelValue
}) => {
  
 
    const [state1, setState1] = useState({
      options: {
        colors:[radialcolor],
        plotOptions: {
          
          radialBar: {
            // startAngle: -180,
            // endAngle: 180,

            track: {
              background: "#f2f2f2",
              strokeWidth: "100%",
             
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: '16px',
                fontFamily: undefined,
                fontWeight: 600,
                color: undefined,
                offsetY: -10
              },
              value: {
                show: true,
                fontSize: "16px",
                color: radialcolor,
                padding:10,
                label:labelValue

                
              },
              total: {
                show: false,
              
                color: radialcolor,
                fontSize: "35px",
                padding:15
              }
            }
          }
        }
      },

      series: [SeriesValue]
    }
    )


    return (
      <div className="donut ">
        <Chart
        
          options={state1.options}
          series={state1.series}
          type="radialBar"
          width={radialwidth}
          className=''
          
        />
      </div>
    );
  
}

export default RadialBar;
