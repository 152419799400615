import React from "react";
import logo1 from "../../assets/brickdemoicons/brick_logo_f.png";
import "./NewHeader.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet, Link, useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { useState, useEffect } from "react";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import ButtonLink from "../ButtonLink/ButtonLink";
import { VscHome } from "react-icons/vsc";
import { PiNotepad } from "react-icons/pi";
import { Container, Nav, Navbar } from "react-bootstrap";

export default function ContractorHeader() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationnumber, setNotificationnumber] = useState("");
  const [notificationmessage, setNotificationmessage] = useState("");
  const [notificationrequestedfor, setNotificationrequestedfor] = useState("");
  const [notificationdate, setNotificationdate] = useState("");
  const [notificationstate, setNotificationstate] = useState(false);
  const [status, setStatus] = useState(false);
  const [headMenuList, setHeadMenuList] = useState([]);
  const [activeMenu, setActiveMenu] = useState("");
  const [logoutstatus, setLogoutstatus] = useState(false);
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [request, setRequest] = useState("");
  const [active, setActive] = useState("Siteupdates");

  const handleItemClick = (item) => {
    setActive(item);
  };

  const backtoproject = () => {
    if (userData.chainageArr && Array.isArray(userData.chainageArr)) {
      userData.chainageArr.splice(0, userData.chainageArr.length);
    }

    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: userData.Pagename,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        companyName: userData.companyName,
        clientId: userData.clientId,
        projectName: userData.projectName,
        projectMenuIndex: userData.projectMenuIndex,
        profileImage: userData.profileImage,
        headerMenus: userData.headerMenus,
        projectMenus: userData.projectMenus,
        sidebarReportsMenus: userData.sidebarReportsMenus,
        sidebarWorkDetailsMenus: userData.sidebarWorkDetailsMenus,
        dropdownViewStatus: userData.dropdownViewStatus,
        chainageArr: [],
        projectStatus: true,
        projectsubname: "Dashboard",
        loginUsername: userData.loginUsername,
        engineerLoginStatus: false,
        searchParams: userData.searchParams,
        statename: userData.statename,
        circle: userData.circle,
        division: userData.division,
        subdivision: userData.subdivision,
      })
    );

    setActive("Siteupdates");
  };

  function notiupdate(projectmenu, id, message, resourcemenu, notitype) {
    setRequest(message);

    if (notitype === "markoneasread") {
      if (message === "Site Update") {
        dispatch(
          updateLoginStatus({
            isLoggedIn: true,
            Pagename: "Projects",
            userName: userData.userName,
            userId: userData.userId,
            role: userData.role,
            token: userData.token,
            projectId: userData.projectId,
            clientId: userData.clientId,
            currentprojectId: id,
            dropdownOption: projectmenu,
            projectType: userData.projectType,
            companyName: userData.companyName,
            projectName: userData.projectName,
            projectMenuIndex: 7,
            resourceMenuKey: "material",
            modalNotificationstatus: true,
            profileImage: userData.profileImage,
            headerMenus: userData.headerMenus,
            projectMenus: userData.projectMenus,
            sidebartoggleStatus: userData.sidebartoggleStatus,
            loginUsername: userData.loginUsername,
            engineerLoginStatus: false,
            subdivision: userData.subdivision,
          })
        );
      } else {
        dispatch(
          updateLoginStatus({
            isLoggedIn: true,
            Pagename: "Dashboard",
            userName: userData.userName,
            userId: userData.userId,
            role: userData.role,
            token: userData.token,
            projectId: userData.projectId,
            clientId: userData.clientId,
            currentprojectId: id,
            dropdownOption: "Project Timeline",
            projectType: userData.projectType,
            companyName: userData.companyName,
            projectName: userData.projectName,
            projectMenuIndex: 0,
            resourceMenuKey: "material",
            modalNotificationstatus: true,
            profileImage: userData.profileImage,
            headerMenus: userData.headerMenus,
            projectMenus: userData.projectMenus,
            sidebartoggleStatus: userData.sidebartoggleStatus,
            loginUsername: userData.loginUsername,
            engineerLoginStatus: false,
            subdivision: userData.subdivision,
          })
        );
      }
    }
  }

  useEffect(() => {
    setHeadMenuList(userData.headerMenus);
    setActiveMenu(userData.headerMenus[0]);
  }, []);

  const [linkname, setLinkname] = useState("");

  function logoutfunc(e) {
    axios({
      method: "PUT",
      url: baseurl + "/api/logout?user_id=" + userData.userId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {})
      .catch((error) => {});
    dispatch(
      updateLoginStatus({
        isLoggedIn: false,
      })
    );
    localStorage.clear();
    window.location.href = "/";
  }

  const location = useLocation();

  return (
    <div className=" position-sticky" style={{ top: "0", zIndex: "999" }}>
      {userData.modalNotificationstatus && request === "Site Update" && (
        <ButtonLink btnPath="/Road" />
      )}
      {userData.modalNotificationstatus && request != "Site Update" && (
        <ButtonLink btnPath="/Dashboard" />
      )}
      <div className=" ">
        <Navbar expand="md" className="p-0"  style={{ backgroundColor: "#07424F" }}>
          <Container fluid className="d-flex justify-content-between p-2">
            <Navbar.Brand >
              <img
                src={logo1}
                width={130}
                className="rounded img-fluid"
                style={{ border: "1px solid #f6fa00" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="" className="bg-white border-0 " />
            <Navbar.Collapse id="" className="d-lg-flex justify-content-end ">
              <Nav
                className="my-2 my-lg-0 nav-info" 
                style={{ maxHeight: "150px" }}
                
              >
                <span className="mx-lg-2">
                  <img src={userData.profileImage} width={55} className="  " />
                </span>
                <span className="d-flex flex-column text-white mx-lg-2">
                <b> {userData.userName}</b>
                {userData.role}
                </span>
                <span className="py-2 mx-md-2">
                <Link
                    onClick={(e) => logoutfunc(e)}
                    className="btn d-inline-flex align-items-center text-white border"
                  >
                    <FiLogOut className="mr-1" />
                    Logout
                  </Link>
                </span>
              </Nav>
              {(location.pathname === "/Siteupdates" ||
          location.pathname === "/Completedworklist") && (
          <div className="shadow-sm bg-white rounded">
            <ul
              className="d-flex d-md-none justify-content-around align-items-center pb-0 mb-0 p-1 "
              style={{ fontWeight: "500", fontSize:'11px' }}
            >
              <li
                onClick={() => handleItemClick("Siteupdates")}
                className={
                  active === "Siteupdates"
                    ? "activetabs mr-4 nav-item"
                    : "mr-4 nav-item"
                }
              >
                <Link className="nav-link text-dark p-0" to="/Siteupdates">
                  Site Updates
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("Completedwork")}
                className={
                  active === "Completedwork"
                    ? "activetabs mr-4 nav-item"
                    : "mr-4 nav-item"
                }
              >
                <Link
                  className="nav-link text-dark d-inline-flex align-items-center p-0"
                  to="/Completedworklist"
                >
                  {/* <PiNotepad style={{fontSize:'18px'}}/> */}
                  Completed Work
                </Link>
              </li>

              <li>
                <Link onClick={backtoproject} to="/ContractorProjectList">
                  Back to project
                </Link>
              </li>
            </ul>
          </div>
        )}  
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {(location.pathname === "/Siteupdates" ||
          location.pathname === "/Completedworklist") && (
          <div className="shadow-sm bg-white ">
            <ul
              className="d-md-flex d-none justify-content-end align-items-center pb-0 mb-0 mr-5 p-2"
              style={{ fontWeight: "500" }}
            >
              <li
                onClick={() => handleItemClick("Siteupdates")}
                className={
                  active === "Siteupdates"
                    ? "activetabs mr-4 nav-item"
                    : "mr-4 nav-item"
                }
              >
                <Link className="nav-link text-dark p-0" to="/Siteupdates">
                  Site Updates
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("Completedwork")}
                className={
                  active === "Completedwork"
                    ? "activetabs mr-4 nav-item"
                    : "mr-4 nav-item"
                }
              >
                <Link
                  className="nav-link text-dark d-inline-flex align-items-center p-0"
                  to="/Completedworklist"
                >
                  {/* <PiNotepad style={{fontSize:'18px'}}/> */}
                  Completed Work
                </Link>
              </li>

              <li>
                <Link onClick={backtoproject} to="/ContractorProjectList">
                  Back to project
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
